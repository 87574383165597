<template>
  <div class="c-table-overview--financials" data-testid="financialplanoverview-child-futurefinancialstableoverview">
    <C_Search @input="filterFutureFinancialPlans($event)" :placeholder="'Search by entity name...'" v-if="futureFinancials.length > 0" />
    <div class="c-table-overview__header">
      <div>Entity</div>
      <div>Owner</div>
      <div>Status</div>
      <div>Demand ID</div>
      <div>Demand Title</div>
      <div>Demanded {{ financialGoalLabel }}</div>
      <div>Proposed {{ financialGoalLabel }}</div>
      <div>Approved {{ financialGoalLabel }}</div>
      <div>Details</div>
    </div>
    <div v-if="futureFinancials.length > 0" class="c-table-overview__body-row--wrapper">
      <div v-for="(entity, index) in filteredFinancials" :key="index">
        <div class="c-table-overview__body-row">
          <div>
            {{ entity.entity_name }}
          </div>
          <div>
            {{ entity.owner }}
          </div>
          <div>{{ entity.status }}</div>
          <div>
            {{ entity.demand_id ? entity.demand_id : "-" }}
          </div>

          <div>
            {{ entity.demand_title ? entity.demand_title : "-" }}
          </div>
          <div>
            {{ entity.demanded_budget ? formatNumber((entity.demanded_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.proposed_budget ? formatNumber((entity.proposed_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.approved_budget ? formatNumber((entity.approved_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            <button @click="handleFutureFinancialsDetails(entity)">View</button>
          </div>
        </div>
      </div>
      <div v-if="filteredFinancials.length === 0">There are no such entities.</div>
    </div>

    <div v-else class="c-table-overview__body-row--empty">There are no entities with planned future financials.</div>
  </div>
  <C_FutureFinancialsModal v-if="showDetailsModal" @close-details-modal="showDetailsModal = !showDetailsModal" :entity="selectedEntity" :financialGoalLabel="financialGoalLabel"></C_FutureFinancialsModal>
</template>
<script lang="ts" setup>
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import { computed, ref, defineProps } from "vue";
  import { useStore } from "vuex";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import C_FutureFinancialsModal from "./modals/C_FutureFinancialsModal.vue";
  import C_Search from "./C_Search.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  const { FETCH_SPECIFIC_FUTURE_FINANCIALS } = VUEX_ACTIONS;
  const props = defineProps<{
    financialGoalLabel?: string;
  }>();
  const store = useStore();
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const showDetailsModal = ref(false);
  const selectedEntity = ref<IFinancialPlan>({});
  const futureFinancials = computed(() => {
    const futureFinancials = store.getters.getFutureFinancials;
    return futureFinancials.filter((item: IFinancialPlan) => item.status == "approved");
  });
  const filteredFinancials = ref(futureFinancials.value);
  const handleFutureFinancialsDetails = (entity: IFinancialPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_FINANCIALS, selectedEntity.value.id);
  };

  const filterFutureFinancialPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredFinancials.value = futureFinancials.value.filter((financials: IFinancialPlan) => {
      if (financials?.entity_name) return financials?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };
</script>
