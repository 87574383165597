import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "createcompany__wrapper",
  "data-testid": "createcompany-wrapper"
}
const _hoisted_2 = { class: "cst-inputbox" }
const _hoisted_3 = { class: "cst-inputbox--body" }
const _hoisted_4 = { class: "cst-inputbox__form cst-inputbox__form--column-wrapper" }
const _hoisted_5 = { class: "createcompany__fields-row" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "createcompany__fields-row" }
const _hoisted_11 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_12 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_13 = { class: "cst-input-field" }
const _hoisted_14 = { class: "cst-input-field__checkbox createcompany__checkbox" }
const _hoisted_15 = { class: "cst-inputbox--flex-wrapper" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "createcompany__success",
  "data-testid": "createcompany-company-added-label"
}
const _hoisted_18 = { key: 0 }

import { ref, computed } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import countries from "../utils/assets/countries.json";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import CreateContact from "./V_CreateContact.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreateCompany',
  setup(__props) {

  const { CREATE_COMPANY, FETCH_ALL_COMPANIES } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const isCompanyCreated = computed(() => store.getters.getIsCompanyCreated);
  const companyName = ref("");
  const companyCity = ref("");
  const companyStreet = ref("");
  const companyHouseNumber = ref("");
  const companyPostal = ref("");
  const companyCountry = ref("");
  const usePostalOnly = ref(false);
  store.dispatch(FETCH_ALL_COMPANIES);

  // Route guard
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  if (!userRoles.value.includes("edit-product-family")) {
    router.push("/");
  }

  const handleCompanyCreation = () => {
    if (usePostalOnly.value) {
      store.dispatch(CREATE_COMPANY, {
        name: companyName.value,
        city: companyCity.value,
        street: null,
        house_number: null,
        postal_code: companyPostal.value,
        country: companyCountry.value,
      });
    } else {
      store.dispatch(CREATE_COMPANY, {
        name: companyName.value,
        city: companyCity.value,
        street: companyStreet.value,
        house_number: companyHouseNumber.value,
        postal_code: companyPostal.value,
        country: companyCountry.value,
      });
    }
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
          _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Add a new Company")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(handleCompanyCreation, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "company-name" }, [
                    _createTextVNode("Name:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "text",
                    id: "company-name",
                    placeholder: "Enter Company Name",
                    required: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((companyName).value = $event)),
                    "data-testid": "createcompany-name"
                  }, null, 512), [
                    [
                      _vModelText,
                      companyName.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { for: "company-city" }, [
                    _createTextVNode("City:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "text",
                    id: "company-city",
                    placeholder: "Enter City",
                    required: "",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((companyCity).value = $event)),
                    "data-testid": "createcompany-city"
                  }, null, 512), [
                    [
                      _vModelText,
                      companyCity.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", { for: "company-country" }, [
                    _createTextVNode("Country:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    id: "company-country",
                    placeholder: "Select Country",
                    required: "",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((companyCountry).value = $event)),
                    "data-testid": "createcompany-country"
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a country", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(countries), (country) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: country.code,
                        value: country.name,
                        "data-testid": "createcompany-country-options"
                      }, _toDisplayString(country.name), 9, _hoisted_9))
                    }), 128))
                  ], 512), [
                    [
                      _vModelSelect,
                      companyCountry.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                (!usePostalOnly.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _cache[11] || (_cache[11] = _createElementVNode("label", { for: "company-street" }, [
                        _createTextVNode("Street:"),
                        _createElementVNode("span", { style: {"color":"red"} }, "*")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "text",
                        id: "company-street",
                        placeholder: "Enter Street",
                        required: "",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((companyStreet).value = $event)),
                        "data-testid": "createcompany-street"
                      }, null, 512), [
                        [
                          _vModelText,
                          companyStreet.value,
                          void 0,
                          { trim: true }
                        ]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!usePostalOnly.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _cache[12] || (_cache[12] = _createElementVNode("label", { for: "company-house-number" }, [
                        _createTextVNode("House Number:"),
                        _createElementVNode("span", { style: {"color":"red"} }, "*")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "text",
                        id: "company-house-number",
                        placeholder: "Enter House Number",
                        required: "",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((companyHouseNumber).value = $event)),
                        "data-testid": "createcompany-house-number"
                      }, null, 512), [
                        [
                          _vModelText,
                          companyHouseNumber.value,
                          void 0,
                          { trim: true }
                        ]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { for: "company-postal" }, [
                    _createTextVNode("Postal Code:"),
                    _createElementVNode("span", { style: {"color":"red"} }, "*")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "text",
                    id: "company-postal",
                    placeholder: "Enter Postal Code",
                    required: "",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((companyPostal).value = $event)),
                    "data-testid": "createcompany-postal"
                  }, null, 512), [
                    [
                      _vModelText,
                      companyPostal.value,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[14] || (_cache[14] = _createElementVNode("label", { for: "selectPlatform" }, "Use Postal Code only", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  id: "selectPlatform",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((usePostalOnly).value = $event)),
                  "data-testid": "createcompany-checkbox"
                }, null, 512), [
                  [_vModelCheckbox, usePostalOnly.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("button", {
                  class: "submit-button cst-button cst-button-primary",
                  type: "submit",
                  disabled: !companyName.value || !companyCity.value || !companyCountry.value || !companyPostal.value || (!usePostalOnly.value && (!companyStreet.value || !companyHouseNumber.value)),
                  "data-testid": "createcompany-submit-button"
                }, " Submit ", 8, _hoisted_16)
              ])
            ])
          ], 32)
        ]),
        (isCompanyCreated.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[15] || (_cache[15] = [
              _createElementVNode("p", { class: "createcompany__success--lead" }, "New Company has been added!", -1),
              _createElementVNode("p", { class: "createcompany__success--sublead" }, "You can now add contacts to it.", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ]),
    (isCompanyCreated.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createVNode(CreateContact, { fromCreateCompany: true })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})