export function handleAxiosError(context: any, error: any, defaultErrorMessage = "An error occurred") {
  if (!error.response) {
    context.dispatch("updateRequestMessage", "Network error or server not reachable");
    return;
  }

  switch (error.response.status) {
    case 403:
      context.dispatch("updateRequestMessage", "ERROR: You don't have permission to use this feature.");
      break;
    case 404:
      context.dispatch("updateRequestMessage", "ERROR: 404 Not Found");
      break;
    default:
      context.dispatch("updateRequestMessage", `ERROR: ${error.response.data.message ? error.response.data.message : defaultErrorMessage}`);
      break;
  }
}
