import { createStore } from "vuex";
import currencyModule from "./modules/currency";
import productFamilyModule from "./modules/productFamily";
import modalsModule from "./modules/modals";
import productsModule from "./modules/products";
import archiveModule from "./modules/archive";
import purchaseRequestsModule from "./modules/purchaseRequest";
import purchaseOrdersModule from "./modules/purchaseOrders";
import filtersModule from "./modules/filters";
import productFilteringModule from "./modules/productFiltering";
import projectsModule from "./modules/projects";
import applicationsModule from "./modules/applications";
import SBUModule from "./modules/sbu";
import regionsModule from "./modules/regions";
import suppliersModule from "./modules/suppliers";
import usersModule from "./modules/users";
import requestMessageModule from "./modules/requestMessage";
import currentUserModule from "./modules/currentUser";
import invoicesModule from "./modules/invoices";
import listItemsModule from "./modules/listItems";
import costUnitsModule from "./modules/costUnits";
import notificationsModule from "./modules/notifications";
import appConfigurationModule from "./modules/appConfiguration";
import imageModule from "./modules/images";
import platformModule from "./modules/platform";
import financialPlanModule from "./modules/financialplanning";
import financialPlanningFiltersModule from "./modules/financialPlanningFilters";
import companiesModule from "./modules/company";
import companyContactModule from "./modules/companyContact";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    currency: currencyModule,
    products: productsModule,
    filters: filtersModule,
    productFamily: productFamilyModule,
    modals: modalsModule,
    productFiltering: productFilteringModule,
    archive: archiveModule,
    purchaseRequests: purchaseRequestsModule,
    purchaseOrders: purchaseOrdersModule,
    projects: projectsModule,
    applications: applicationsModule,
    SBU: SBUModule,
    suppliers: suppliersModule,
    users: usersModule,
    regions: regionsModule,
    requestMessage: requestMessageModule,
    currentUser: currentUserModule,
    invoices: invoicesModule,
    listItems: listItemsModule,
    costUnits: costUnitsModule,
    notifications: notificationsModule,
    appConfiguration: appConfigurationModule,
    image: imageModule,
    platform: platformModule,
    financialPlan: financialPlanModule,
    financialPlanningFilters: financialPlanningFiltersModule,
    companiesModule: companiesModule,
    companyContactModule: companyContactModule,
  },
});
