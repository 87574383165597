<template>
  <RequestMessage></RequestMessage>
  <div class="createcompany__wrapper" data-testid="createcompany-wrapper">
    <div class="cst-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Add a new Company</div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="handleCompanyCreation">
          <div class="cst-inputbox__form cst-inputbox__form--column-wrapper">
            <div class="createcompany__fields-row">
              <div class="cst-input-field">
                <label for="company-name">Name:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="company-name" placeholder="Enter Company Name" required v-model.trim="companyName" data-testid="createcompany-name" />
              </div>
              <div class="cst-input-field">
                <label for="company-city">City:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="company-city" placeholder="Enter City" required v-model.trim="companyCity" data-testid="createcompany-city" />
              </div>

              <div class="cst-input-field">
                <label for="company-country">Country:<span style="color: red">*</span></label>
                <select class="cst-select-field" id="company-country" placeholder="Select Country" required v-model.trim="companyCountry" data-testid="createcompany-country">
                  <option disabled selected value="">Select a country</option>
                  <option v-for="country in countries" :key="country.code" :value="country.name" data-testid="createcompany-country-options">
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="createcompany__fields-row">
              <div class="cst-input-field" v-if="!usePostalOnly">
                <label for="company-street">Street:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="company-street" placeholder="Enter Street" required v-model.trim="companyStreet" data-testid="createcompany-street" />
              </div>

              <div class="cst-input-field" v-if="!usePostalOnly">
                <label for="company-house-number">House Number:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="company-house-number" placeholder="Enter House Number" required v-model.trim="companyHouseNumber" data-testid="createcompany-house-number" />
              </div>

              <div class="cst-input-field">
                <label for="company-postal">Postal Code:<span style="color: red">*</span></label>
                <input class="cst-input" type="text" id="company-postal" placeholder="Enter Postal Code" required v-model.trim="companyPostal" data-testid="createcompany-postal" />
              </div>
            </div>
            <div class="cst-input-field__checkbox createcompany__checkbox">
              <label for="selectPlatform">Use Postal Code only</label>
              <input type="checkbox" id="selectPlatform" v-model="usePostalOnly" data-testid="createcompany-checkbox" />
            </div>
            <div class="cst-inputbox--flex-wrapper">
              <button
                class="submit-button cst-button cst-button-primary"
                type="submit"
                :disabled="!companyName || !companyCity || !companyCountry || !companyPostal || (!usePostalOnly && (!companyStreet || !companyHouseNumber))"
                data-testid="createcompany-submit-button"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <div v-if="isCompanyCreated" class="createcompany__success" data-testid="createcompany-company-added-label">
        <p class="createcompany__success--lead">New Company has been added!</p>
        <p class="createcompany__success--sublead">You can now add contacts to it.</p>
      </div>
    </div>
  </div>
  <div v-if="isCompanyCreated">
    <CreateContact :fromCreateCompany="true"></CreateContact>
  </div>
</template>

<script lang="ts" setup>
  import { ref, computed } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import countries from "../utils/assets/countries.json";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import CreateContact from "./V_CreateContact.vue";

  const { CREATE_COMPANY, FETCH_ALL_COMPANIES } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const isCompanyCreated = computed(() => store.getters.getIsCompanyCreated);
  const companyName = ref("");
  const companyCity = ref("");
  const companyStreet = ref("");
  const companyHouseNumber = ref("");
  const companyPostal = ref("");
  const companyCountry = ref("");
  const usePostalOnly = ref(false);
  store.dispatch(FETCH_ALL_COMPANIES);

  // Route guard
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  if (!userRoles.value.includes("edit-product-family")) {
    router.push("/");
  }

  const handleCompanyCreation = () => {
    if (usePostalOnly.value) {
      store.dispatch(CREATE_COMPANY, {
        name: companyName.value,
        city: companyCity.value,
        street: null,
        house_number: null,
        postal_code: companyPostal.value,
        country: companyCountry.value,
      });
    } else {
      store.dispatch(CREATE_COMPANY, {
        name: companyName.value,
        city: companyCity.value,
        street: companyStreet.value,
        house_number: companyHouseNumber.value,
        postal_code: companyPostal.value,
        country: companyCountry.value,
      });
    }
  };
</script>
