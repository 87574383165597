<template>
  <div class="c-prodprojectappmodal__wrapper" v-if="isModalEnabled">
    <div class="c-prodprojectappmodal__container">
      <div class="c-prodprojectappmodal__headline">
        <p class="c-prodprojectappmodal__title">{{ prodName }} Details</p>
        <img src="../../../public/remove.svg" height="30" width="30" alt="Close modal icon" @click="handleCloseProductModal()" />
      </div>
      <div class="c-prodprojectappmodal__content">
        <div class="c-prodprojectappmodal__data">
          <div class="c-prodprojectappmodal__table">
            <div v-if="getIsProductPOListFetched">
              <table class="v-showinvoices__table--overview" aria-describedby="Table of invoices" v-if="productPurchaseOrders.length > 0">
                <thead>
                  <tr class="v-showinvoices__table-head-row">
                    <th v-for="(item, index) in tableHeaders" :key="index">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in productPurchaseOrders as any" :key="index">
                  <tr class="v-showinvoices__table-row">
                    <td>{{ formatDate(item.podate) }}</td>
                    <td>{{ item.ordertext }}</td>
                    <td>{{ convertUsernameFromId(item.prowner) }}</td>
                    <td>{{ item.customercontact }}</td>
                    <td>
                      {{ formatNumber((item.orderamount * currencyRate).toFixed(2)) }}
                      EUR
                    </td>
                    <td>
                      {{ formatNumber(Number(item.amountCurrentYear * currencyRate).toFixed(2)) }}
                      EUR
                    </td>
                    <td>
                      <button title="Show" class="c-requestbtns c-requestbtns-secondary-show" @click="toggleDetailsDropdown(item, index)">
                        <p>{{ expandedIndex === index ? "Hide" : "Show" }}</p>
                        <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                          <path
                            d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="expandedIndex !== null && expandedIndex === index">
                    <td colspan="13">
                      <div v-if="expandedItem.listitems && expandedItem.listitems.length > 0">
                        <h4 class="v-showinvoices__listitems--overview"><strong>List Items In: </strong>{{ expandedItem.ordertext }}</h4>
                        <div class="v-showinvoices__li-table-header">
                          <p class="v-showinvoices__li-table-header--first">No.</p>
                          <p>Name</p>
                          <p>Cost Type</p>
                          <p>Resource</p>
                          <p>Person Days</p>
                          <p>Daily rate</p>
                          <p class="v-showinvoices__li-table-header--last">Amount</p>
                          <p>Current Year</p>
                          <p>Remaining</p>
                        </div>
                        <div class="v-showinvoices__li-table--wrapper">
                          <div v-for="(item, index) in expandedItem.listitems" :key="index">
                            <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                              <p class="v-showinvoices__li-table-row--first">
                                {{ index + 1 }}
                              </p>
                              <p>{{ item.name }}</p>
                              <p>{{ item.costtype }}</p>
                              <p>
                                {{ item.resourcename != "" ? item.resourcename : "-" }}
                              </p>
                              <p>
                                {{ item.quantity != 0 ? item.quantity : "-" }}
                              </p>
                              <p>{{ item.daily_rate }}</p>
                              <p>
                                {{ formatNumber((item.amount * currencyRate).toFixed(2)) + " " + item.currency }}
                              </p>
                              <p>
                                {{ formatNumber(Number(item.amountCurrentYear * currencyRate).toFixed(2)) + " " + item.currency }}
                              </p>
                              <p>
                                {{ formatNumber(Number(item.li_remaining_amount * currencyRate).toFixed(2)) + " " + item.currency }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="isplatform == false">
                        <h4 class="v-showinvoices__listitems--invoices">
                          <strong>Invoices Including:</strong>
                          {{ expandedItem.ordertext }}
                        </h4>

                        <div v-if="!areInvoicesforFetched" class="cst-spinner"></div>
                        <div v-else-if="invoicesFromProjAppPo.length > 0">
                          <div class="v-showinvoices__li-table-header">
                            <p>Name</p>
                            <p>Total Amount</p>
                            <p class="v-showinvoices__li-table-header--last">Details</p>
                          </div>
                          <div class="v-showinvoices__li-table--wrapper">
                            <div v-for="(item, index) in invoicesFromProjAppPo" :key="index">
                              <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                                <p>{{ item.name }}</p>
                                <p>
                                  {{ formatNumber((item.total_amount * currencyRate).toFixed(2)) + " " + selectedCurrency }}
                                </p>
                                <div>
                                  <button class="c-requestbtns c-requestbtns-secondary-show" @click="showInvoiceDetails(item)">
                                    {{ expandedInvoiceDetails && selectedInvoice.id === item.id ? "Hide" : "Show" }}
                                    <svg width="12px" height="12px" fill="#000000" viewBox="-1 0 19 19" class="cf-icon-svg">
                                      <path
                                        d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="expandedInvoiceDetails">
                            <h4 class="v-showinvoices__listitems--invoices"><strong>Invoice Details: </strong>{{ selectedInvoice.name }}</h4>
                            <div class="v-showinvoices__li-table-header">
                              <p class="v-showinvoices__li-table-header--first">No.</p>
                              <p>Name</p>
                              <p>Cost Type</p>
                              <p>Resource</p>
                              <p>Person Days</p>
                              <p>Daily rate</p>
                              <p>Amount</p>
                            </div>
                            <div class="v-showinvoices__li-table--wrapper">
                              <div v-for="(item, index) in selectedInvoice.listitems" :key="index">
                                <div class="v-showinvoices__li-invoice-details v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                                  <p class="v-showinvoices__li-invoice-details--first">
                                    {{ index + 1 }}
                                  </p>
                                  <p>{{ item.list_item_name }}</p>
                                  <p>{{ item.cost_type }}</p>
                                  <p>
                                    {{ item.resource_name ? item.resource_name : "-" }}
                                  </p>
                                  <p>
                                    {{ item.quantity && item.quantity > 0 ? item.quantity : "-" }}
                                  </p>
                                  <p>{{ item.daily_rate }}</p>
                                  <p>
                                    {{ formatNumber((item.amount * currencyRate).toFixed(2)) + " " + item.currency }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="v-showinvoices__listitems--remaining">
                            <div>
                              <strong>Total Invoice Amount:</strong>
                              {{ formatNumber((sumTotalInvoiceAmount * currencyRate).toFixed(2)) + " " + item.currency }}
                            </div>
                            <div>
                              <strong>Remaining PO Amount:</strong>
                              {{ formatNumber(Number(item.po_remaining_amount * currencyRate).toFixed(2)) + " " + item.currency }}
                            </div>
                          </div>
                        </div>
                        <div v-else class="v-showinvoices__li-table--noinvoices">No invoices found for this product.</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="c-prodprojectappmodal__no-orders">No purchase orders found for this product.</div>
            </div>
            <div v-else class="cst-spinner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { IInvoice, IInvoiceFromPO } from "@/utils/interfaces/IInvoice";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { Ref, computed, ref, defineProps, defineEmits } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";

  const props = defineProps({
    prodName: { type: String, default: "" },
    isModalEnabled: { type: Boolean, default: false },
    isplatform: { type: Boolean, default: false },
  });
  const emit = defineEmits(["isProdModalVisible"]);
  const { UPDATE_PO_LIST_FETCHED_STATE, FETCH_INVOICES_FROM_PO } = VUEX_ACTIONS;
  const store = useStore();
  const expandedItem = ref();
  const expandedIndex = ref<number | null>(null);
  const expandedInvoiceDetails = ref(false);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const productPurchaseOrders = computed(() => store.getters.getProductPurchaseOrders);
  const getIsProductPOListFetched = computed(() => store.getters.getIsProductPOListFetched);

  const invoicesFromProjAppPo = computed(() => store.getters.getInvoicesFromProjAppPO);
  const areInvoicesforFetched = computed(() => store.getters.getAreInvoicesForPOFetched);

  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const selectedInvoice = ref();

  let listItemId;

  const invoicesArray = computed(() => invoicesFromProjAppPo.value);

  let listItemsfromPO;
  const sumLIfromInvoices = ref(0);

  const tableHeaders: Ref<string[]> = ref(["PO Date", "PO Name", "PO Owner", "Customer Contact", "Order Amount", "Current Year", "Details"]);

  const toggleDetailsDropdown = async (item: IInvoice, index: number) => {
    if (expandedIndex.value === index) {
      expandedIndex.value = null;
      sumLIfromInvoices.value = 0;
    } else {
      expandedIndex.value = index;
      expandedItem.value = item;
      listItemsfromPO = expandedItem.value.listitems;

      store.commit("setAreInvoicesForPOFetched", false);
      await store.dispatch(FETCH_INVOICES_FROM_PO, item.id);

      if (listItemsfromPO) {
        listItemsfromPO.forEach((listItem: IListItem) => {
          listItemId = listItem.id;
          sumLIfromInvoices.value = sumListItemAmountsById(listItemId.toString());
        });
      }
    }
  };

  function sumListItemAmountsById(listItemID: string) {
    let sum = 0;
    invoicesArray.value.forEach((invoice: IInvoiceFromPO) => {
      invoice.listitems.forEach((listItem) => {
        if (listItem.po_listitem_id === String(listItemID)) {
          sum += parseFloat(listItem.amount);
        }
      });
    });
    return sum;
  }

  const ownerUsername = ref("");
  const allUsers = computed(() => store.getters.getUsers);
  const focusedInvoiceName = ref("");

  function convertUsernameFromId(id: number): string {
    allUsers.value.filter((user: IUser) => {
      if (user.id === id) {
        ownerUsername.value = user.username;
      }
    });
    return ownerUsername.value;
  }

  function showInvoiceDetails(invoice: IInvoiceFromPO) {
    if (expandedInvoiceDetails.value && invoice.id === selectedInvoice.value?.id) {
      selectedInvoice.value = ref();
      expandedInvoiceDetails.value = !expandedInvoiceDetails.value;
    } else if (expandedInvoiceDetails.value && invoice.id !== selectedInvoice.value?.id) {
      selectedInvoice.value = invoice;
      focusedInvoiceName.value = "";
      focusedInvoiceName.value = invoice.name;
    } else {
      selectedInvoice.value = invoice;
      expandedInvoiceDetails.value = !expandedInvoiceDetails.value;
      focusedInvoiceName.value = "";
      focusedInvoiceName.value = invoice.name;
    }
  }

  const sumTotalInvoiceAmount = computed(() => {
    let invoicesTotalSum = 0;
    // Go through all invoices from PO and sum
    invoicesFromProjAppPo.value.forEach((item: IInvoiceFromPO) => {
      invoicesTotalSum += item.total_amount;
    });

    return invoicesTotalSum;
  });

  const handleCloseProductModal = () => {
    // Emit to parent to close modal
    emit("isProdModalVisible");
    store.dispatch(UPDATE_PO_LIST_FETCHED_STATE);
  };
</script>
