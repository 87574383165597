import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IFinancialPlan, IFinancialPlanComment } from "@/utils/interfaces/IFinancialPlan";

interface FinancialPlanningState {
  futureFinancials: IFinancialPlan[];
  futureFinancialsFetched: boolean;
  createdFutureFinancials: IFinancialPlan | null;
  createdComment: IFinancialPlanComment | null;
  specificFutureFinancials: IFinancialPlan | null;
  specificFutureFinancialsFetched: boolean;
  entityType: string;
}

interface FinancialPlanningContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: FinancialPlanningState = {
  futureFinancials: [],
  futureFinancialsFetched: false,
  createdFutureFinancials: null,
  createdComment: null,
  specificFutureFinancials: null,
  specificFutureFinancialsFetched: false,
  entityType: "",
};

const mutations = {
  setFutureFinancials(state: FinancialPlanningState, payload: IFinancialPlan[]) {
    state.futureFinancials = payload;
  },
  setFutureFinancialsFetched(state: FinancialPlanningState, payload: boolean) {
    state.futureFinancialsFetched = payload;
  },
  setCreatedFutureFinancials(state: FinancialPlanningState, payload: IFinancialPlan | null) {
    state.createdFutureFinancials = payload;
  },
  setCreatedComment(state: FinancialPlanningState, payload: IFinancialPlanComment) {
    state.createdComment = payload;
  },
  setSpecificFutureFinancials(state: FinancialPlanningState, payload: IFinancialPlan | null) {
    state.specificFutureFinancials = payload;
  },
  setSpecificFutureFinancialsFetched(state: FinancialPlanningState, payload: boolean) {
    state.specificFutureFinancialsFetched = payload;
  },
  setEntityType(state: FinancialPlanningState, payload: string) {
    state.entityType = payload;
  },
};

const getters = {
  getFutureFinancials: (state: FinancialPlanningState) => state.futureFinancials,
  getFutureFinancialsFetched: (state: FinancialPlanningState) => state.futureFinancialsFetched,
  getCreatedFutureFinancials: (state: FinancialPlanningState) => state.createdFutureFinancials,
  getCreatedComment: (state: FinancialPlanningState) => state.createdComment,
  getSpecificFutureFinancials: (state: FinancialPlanningState) => state.specificFutureFinancials,
  getSpecificFutureFinancialsFetched: (state: FinancialPlanningState) => state.specificFutureFinancialsFetched,
  getEntityType: (state: FinancialPlanningState) => state.entityType,
};

const actions = {
  async fetchFutureFinancials(context: FinancialPlanningContext) {
    const futureFinancials: IFinancialPlan[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets`);
      for (const id in response.data) {
        futureFinancials.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setFutureFinancials", futureFinancials);
      context.commit("setFutureFinancialsFetched", true);
    } catch (error) {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error: Unable to fetch future financials`);
    }
  },

  async fetchSpecificFutureFinancials(context: FinancialPlanningContext, futureFinancialsId: string) {
    let futureFinancials: IFinancialPlan = {};
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets/${futureFinancialsId}`);
      futureFinancials = response.data;
      context.commit("setSpecificFutureFinancials", futureFinancials);
      context.commit("setSpecificFutureFinancialsFetched", true);
    } catch (error) {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error: Unable to fetch future financials`);
    }
  },

  async createFutureFinancialsPlan(context: FinancialPlanningContext, payload: IFinancialPlan) {
    const senderId = payload.sender_id;
    const message = payload.description;
    delete payload.sender_id;
    delete payload.description;
    await AXIOS_REQUEST("POST", "/futurebudgets", payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully created a future financials request.");
        const payload = {
          sender_id: Number(senderId),
          future_budget_id: Number(response.data.id),
          message: message,
        };
        context.commit("setCreatedFutureFinancials", response.data);
        context.dispatch("createFinancialPlanComment", payload);
        context.dispatch("fetchFutureFinancials");
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to create future financials plan!");
        console.error(error);
      });
  },

  async approveFutureFinancialsPlan(
    context: FinancialPlanningContext,
    payload: {
      id: number;
      approved_budget: number;
      status: string;
    }
  ) {
    await AXIOS_REQUEST("PUT", `/futurebudgets/${payload.id}`, payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully approved future financials request.");
        context.dispatch("fetchFutureFinancials");
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to approve future financials request!");
        console.error(error);
      });
  },

  async declineFutureFinancialsPlan(
    context: FinancialPlanningContext,
    payload: {
      id: number;
      status: string;
      sender_id?: number;
      description?: string;
    }
  ) {
    const senderId = payload.sender_id;
    const message = payload.description;
    delete payload.sender_id;
    delete payload.description;
    await AXIOS_REQUEST("PUT", `/futurebudgets/${payload.id}`, payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully declined future financials request.");
        context.dispatch("fetchFutureFinancials");

        const payload = {
          sender_id: Number(senderId),
          future_budget_id: Number(response.data.id),
          message: message,
        };
        context.dispatch("createFinancialPlanComment", payload);
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to decline future financials plan!");
        console.error(error);
      });
  },

  async createFinancialPlanComment(context: FinancialPlanningContext, payload: IFinancialPlanComment) {
    await AXIOS_REQUEST("POST", "/futurebudgetcomments", payload)
      .then((response) => {
        context.commit("setCreatedComment", response.data);
        context.dispatch("fetchSpecificFutureFinancials", payload.future_budget_id);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const financialPlanModule = {
  state,
  getters,
  mutations,
  actions,
};

export default financialPlanModule;
