import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-polist__main-container" }
const _hoisted_2 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "v-listpo-spinner"
}
const _hoisted_3 = {
  key: 1,
  class: "v-polist__table-container",
  "data-testid": "v-polist__table"
}
const _hoisted_4 = {
  key: 0,
  class: "v-polist__table-wrapper"
}
const _hoisted_5 = {
  class: "v-polist__table v-polist__list-table",
  "aria-describedby": "Table of purchase orders"
}
const _hoisted_6 = { class: "v-polist__table-head-row" }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { "data-testid": "vpolist-prordernumber" }
const _hoisted_9 = { "data-testid": "vpolist-prstatus" }
const _hoisted_10 = { "data-testid": "vpolist-prownername" }
const _hoisted_11 = {
  "data-testid": "vpolist-customercontact",
  class: "v-polist__table-row--contact"
}
const _hoisted_12 = {
  key: 0,
  "data-testid": "vpolist-sbuname"
}
const _hoisted_13 = {
  "data-testid": "vpolist-ordertext",
  class: "v-polist__table-row--ordertext"
}
const _hoisted_14 = {
  key: 1,
  "data-testid": "vpolist-suppliername"
}
const _hoisted_15 = { "data-testid": "vpolist-poamount" }
const _hoisted_16 = { "data-testid": "vpolist-currentYear" }
const _hoisted_17 = { "data-testid": "vpolist-nextYear" }
const _hoisted_18 = { "data-testid": "vpolist-poremainingamount" }
const _hoisted_19 = { "data-testid": "vpolist-pocurrency" }
const _hoisted_20 = {
  "data-testid": "vpolist-podate",
  class: "v-polist__table-row--startdate"
}
const _hoisted_21 = {
  "data-testid": "vpolist-deliverydate",
  class: "v-polist__table-row--deliverydate"
}
const _hoisted_22 = {
  key: 2,
  "data-testid": "vpolist-accountingelement"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { key: 3 }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 2 }
const _hoisted_28 = {
  key: 0,
  class: "v-polist__no-orders-text",
  "data-testid": "v-polist-no-orders"
}

import { ref, computed, watch } from "vue";

  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { sortByColumn } from "@/utils/helpers/sortByColumn";
  import ListPODetails from "@/components/modals/C_ListPODetails.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_ListPO',
  setup(__props) {

  const { CHANGE_PO_DETAILS_VIEW, FETCH_PURCHASE_ORDERS, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const selectedPOName = ref("");
  const selectedItem = ref({} as IUserOrders);
  const orders = computed<IUserOrders[]>(() => store.getters.getPurchaseOrders);
  const filteredOrders = ref(orders.value.slice(0, 10));
  const paginatedList = ref(orders.value.slice(0, 10));

  const totalPages = computed(() => {
    if (orders.value.length > 10 && orders.value.length % 10 != 0) {
      return Math.floor(orders.value.length / 10) + 1;
    } else {
      return orders.value.length / 10;
    }
  });

  const areOrdersFetched = computed(() => store.getters.getAreOrdersFetched);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const canCreateInvoice = computed(() => {
    return userRoles.value?.includes("edit-invoice") ?? false;
  });

  const isOrderListEmpty = computed(() => {
    if (orders.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const accountingElementEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_accounting_element;
    } else {
      return false;
    }
  });

  const createInvoiceEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.create_invoice_enabled;
    } else {
      return false;
    }
  });

  const fetchPurchaseOrders = () => {
    store.dispatch(FETCH_PURCHASE_ORDERS);
  };

  fetchPurchaseOrders();

  const showPoDetails = (item: IUserOrders) => {
    store.dispatch(CHANGE_PO_DETAILS_VIEW, true);
    selectedPOName.value = item.ordertext;
    selectedItem.value = item;
  };

  const createInvoice = async (item: IUserOrders) => {
    store.commit("setInvoiceFromOrder", item);
    setTimeout(() => {
      router.push("/create-invoice");
    }, 500);
  };

  const filterPO = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredOrders.value = orders.value.filter((order: IUserOrders) => {
      if (order.ordertext) return order.ordertext.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
    if (searchTerm === "") {
      filteredOrders.value = paginatedList.value;
    }
  };

  watch([orders], () => {
    paginatedList.value = orders.value?.slice(0, 10);
    filteredOrders.value = orders.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IUserOrders>) => {
    filteredOrders.value = filteredList;
    paginatedList.value = filteredList;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _cache[20] || (_cache[20] = _createElementVNode("h1", {
        class: "v-polist__title-all",
        "data-testid": "v-polist__heading"
      }, "List of all Purchase Orders", -1)),
      (!areOrdersFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (orders.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(C_Search, {
                placeholder: 'Search by description...',
                onInput: _cache[0] || (_cache[0] = ($event: any) => (filterPO($event)))
              }),
              (filteredOrders.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("table", _hoisted_5, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", _hoisted_6, [
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-ordernum",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(sortByColumn)('ordernumber', filteredOrders.value)))
                          }, "Order Number"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-status",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(sortByColumn)('status', filteredOrders.value)))
                          }, "Status"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-prownername",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(sortByColumn)('prownername', filteredOrders.value)))
                          }, "Owner"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-customercontact",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(sortByColumn)('customercontact', filteredOrders.value)))
                          }, "Customer Contact"),
                          (sbuEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", {
                                key: 0,
                                "data-testid": "vlistpo-ordernumber-sort-sbuname",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(sortByColumn)('sbuname', filteredOrders.value)))
                              }, "Buying Unit"))
                            : _createCommentVNode("", true),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-ordertext",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(sortByColumn)('ordertext', filteredOrders.value)))
                          }, "Description"),
                          (supplierEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", {
                                key: 1,
                                "data-testid": "vlistpo-ordernumber-sort-suppliername",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(sortByColumn)('suppliername', filteredOrders.value)))
                              }, "Provider"))
                            : _createCommentVNode("", true),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-orderamount",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(sortByColumn)('orderamount', filteredOrders.value)))
                          }, "PO Amount"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-amountCurrentYear",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(sortByColumn)('orderamount', filteredOrders.value)))
                          }, "Current Year"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-amountNextYear",
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(sortByColumn)('orderamount', filteredOrders.value)))
                          }, "Next Year"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-remainingamount",
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (_unref(sortByColumn)('po_remaining_amount', filteredOrders.value)))
                          }, "Remaining Amount"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-currency",
                            onClick: _cache[12] || (_cache[12] = ($event: any) => (_unref(sortByColumn)('currency', filteredOrders.value)))
                          }, "Currency"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-podate",
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_unref(sortByColumn)('podate', filteredOrders.value)))
                          }, "Start Date"),
                          _createElementVNode("th", {
                            "data-testid": "vlistpo-ordernumber-sort-deliverydate",
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (_unref(sortByColumn)('deliverydate', filteredOrders.value))),
                            class: "v-polist__table-head-row--deliverydate"
                          }, "Delivery Date (End Date)"),
                          (accountingElementEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", {
                                key: 2,
                                onClick: _cache[15] || (_cache[15] = ($event: any) => (_unref(sortByColumn)('accountingelement', filteredOrders.value))),
                                "data-testid": "vlistpo-ordernumber-sort-accountingelement"
                              }, "Accounting Element"))
                            : _createCommentVNode("", true),
                          _cache[16] || (_cache[16] = _createElementVNode("th", null, "Details", -1)),
                          (canCreateInvoice.value && createInvoiceEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_7, "Create Invoice"))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredOrders.value, (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.id,
                            class: "v-polist__table-row",
                            "data-testid": "vlistpo-tablerow"
                          }, [
                            _createElementVNode("td", _hoisted_8, _toDisplayString(item.ordernumber), 1),
                            _createElementVNode("td", _hoisted_9, _toDisplayString(item.status), 1),
                            _createElementVNode("td", _hoisted_10, _toDisplayString(item.prownername), 1),
                            _createElementVNode("td", _hoisted_11, _toDisplayString(item.customercontact), 1),
                            (sbuEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(item.sbuname), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", _hoisted_13, _toDisplayString(item.ordertext), 1),
                            (supplierEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_14, _toDisplayString(item.suppliername), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", _hoisted_15, _toDisplayString(_unref(formatNumber)((item.orderamount * currencyRate.value).toFixed(2))), 1),
                            _createElementVNode("td", _hoisted_16, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate.value).toFixed(2))), 1),
                            _createElementVNode("td", _hoisted_17, _toDisplayString(_unref(formatNumber)(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate.value).toFixed(2))), 1),
                            _createElementVNode("td", _hoisted_18, _toDisplayString(_unref(formatNumber)((item.po_remaining_amount * currencyRate.value).toFixed(2))), 1),
                            _createElementVNode("td", _hoisted_19, _toDisplayString(currency.value), 1),
                            _createElementVNode("td", _hoisted_20, _toDisplayString(_unref(formatDate)(item.podate)), 1),
                            _createElementVNode("td", _hoisted_21, _toDisplayString(item.deliverydate ? _unref(formatDate)(item.deliverydate) : ""), 1),
                            (accountingElementEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_22, _toDisplayString(item.accountingelement), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                title: "Show",
                                class: "c-requestbtns c-requestbtns-secondary-show",
                                "data-testid": "vpolist-showpobtn",
                                onClick: ($event: any) => (showPoDetails(item))
                              }, _cache[17] || (_cache[17] = [
                                _createElementVNode("p", null, "Show", -1),
                                _createElementVNode("svg", {
                                  fill: "#000000",
                                  width: "15px",
                                  height: "15px",
                                  viewBox: "-1 0 19 19",
                                  class: "cf-icon-svg"
                                }, [
                                  _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                ], -1)
                              ]), 8, _hoisted_23)
                            ]),
                            (canCreateInvoice.value && createInvoiceEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_24, [
                                  _createElementVNode("button", {
                                    title: "Add Invoice",
                                    class: "c-requestbtns c-requestbtns-secondary-add-invoice",
                                    "data-testid": "v-polist_invoicebtn",
                                    onClick: ($event: any) => (createInvoice(item))
                                  }, _cache[18] || (_cache[18] = [
                                    _createTextVNode(" Invoice "),
                                    _createElementVNode("svg", {
                                      width: "14px",
                                      height: "14px",
                                      viewBox: "0 0 33 33",
                                      version: "1.1",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                      "xmlns:sketch": "http://www.bohemiancoding.com/sketch/ns"
                                    }, [
                                      _createElementVNode("g", {
                                        id: "Page-1",
                                        stroke: "none",
                                        "stroke-width": "1",
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        "sketch:type": "MSPage"
                                      }, [
                                        _createElementVNode("g", {
                                          id: "Icon-Set",
                                          "sketch:type": "MSLayerGroup",
                                          transform: "translate(-464.000000, -1087.000000)",
                                          fill: "#000000"
                                        }, [
                                          _createElementVNode("path", {
                                            d: "M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z",
                                            id: "plus-circle",
                                            "sketch:type": "MSShapeGroup"
                                          })
                                        ])
                                      ])
                                    ], -1)
                                  ]), 8, _hoisted_25)
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[19] || (_cache[19] = [
                    _createElementVNode("p", { class: "v-polist--empty" }, "No orders available.", -1)
                  ]))),
              (totalPages.value > 1)
                ? (_openBlock(), _createBlock(C_Pagination, {
                    key: 2,
                    totalPages: totalPages.value,
                    itemList: orders.value,
                    onPaginateList: paginateList,
                    "data-testid": "pages-indicator"
                  }, null, 8, ["totalPages", "itemList"]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_27, [
              (isOrderListEmpty.value)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_28, "No Purchase Orders to show!"))
                : _createCommentVNode("", true)
            ])),
      (selectedItem.value.id)
        ? (_openBlock(), _createBlock(ListPODetails, {
            key: 3,
            selectedPOName: selectedPOName.value,
            selectedItem: selectedItem.value,
            supplierNameEnabled: supplierEnabled.value
          }, null, 8, ["selectedPOName", "selectedItem", "supplierNameEnabled"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})