<template>
  <div class="c-budgetsummary__wrapper" :class="[isOverview]">
    <div class="c-budgetsummary__financials" :class="[sizeClass]">
      <div :class="size === 'small' ? 'c-budgetsummary__financials-card' : ''">
        <div class="c-budgetsummary__budget--wrapper" :class="budgetlimit || target ? 'c-budgetsummary__budget' : ''" data-testid="budgetsummary-wrapper">
          {{ financialGoalLabel }}
          <img v-if="size === 'small' && (budgetlimit > 0 || target > 0)" :src="'./info.svg'" width="16" height="16" alt="info" />
          <div class="c-budgetsummary__budget--hover">
            <div>
              <strong>{{ financialHoverLabel }}: </strong>
              <div>
                {{ budgetlimit ? formatNumber(((budgetlimit - budget) * currencyRate).toFixed(2)) : target ? formatNumber((target * currencyRate).toFixed(2)) : "-" }}
                {{ currency }}
              </div>
            </div>
          </div>
        </div>

        <p class="c-budgetsummary__financials-budget">
          {{ budget ? formatNumber((budget * currencyRate).toFixed(2)) : "-" }}
          <br v-if="size === 'small'" />
          <span class="c-budgetsummary__currency">{{ currency }}</span>
        </p>
      </div>
      <div class="c-budgetsummary__spacer" :class="[spacerSizeClass]"></div>
      <div :class="size === 'small' ? 'c-budgetsummary__financials-card' : ''">
        <div class="c-budgetsummary__forecast--wrapper" :class="size === 'small' && forecast ? 'c-budgetsummary__forecast' : ''">
          Forecast
          <img v-if="size === 'small' && forecast > 0" :src="'./info.svg'" width="16" height="16" alt="info" />
          <div class="c-budgetsummary__forecast--hover">
            <div v-if="hasplatformcost">
              <strong>Regular forecast: </strong>{{ forecast ? formatNumber(((forecast - Number(platformcost)) * currencyRate).toFixed(2)) : "-" }}
              {{ currency }}
            </div>
            <div v-if="!hasplatformcost && (productforecast || budgetlimit)">
              <strong>Product forecast: </strong>{{ forecast ? formatNumber((Number(productforecast) * currencyRate).toFixed(2)) : "-" }}
              {{ currency }}
            </div>
            <div v-if="!hasplatformcost">
              <strong>Proj/App forecast: </strong>{{ forecast ? formatNumber(((forecast - Number(platformforecast) - Number(productforecast)) * currencyRate).toFixed(2)) : "-" }}
              {{ currency }}
            </div>
            <div>
              <strong>Platform forecast: </strong>{{ platformcost ? formatNumber((Number(platformcost) * currencyRate).toFixed(2)) : platformforecast > 0 ? formatNumber((Number(platformforecast) * currencyRate).toFixed(2)) : "-" }}
              {{ currency }}
            </div>
          </div>
        </div>

        <p :class="getColorClass(forecast, budget)">
          {{ forecast ? formatNumber((forecast * currencyRate).toFixed(2)) : "-" }}
          <br v-if="size === `small`" />
          <span class="c-budgetsummary__currency">{{ currency }}</span>
        </p>
      </div>
      <div class="c-budgetsummary__spacer" :class="[spacerSizeClass]"></div>
      <div :class="size === 'small' ? 'c-budgetsummary__financials-card' : ''">
        <p>Actuals</p>
        <p :class="getColorClass(actuals, forecast)">
          {{ actuals ? formatNumber((actuals * currencyRate).toFixed(2)) : "-" }}
          <br v-if="size === `small`" />
          <span class="c-budgetsummary__currency">{{ currency }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed } from "vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { useStore } from "vuex";

  export default defineComponent({
    props: {
      size: {
        type: String as PropType<"small" | "big">,
        required: true,
      },
      budget: {
        type: Number,
        required: true,
      },
      actuals: {
        type: Number as PropType<number>,
        default: 0,
      },
      forecast: {
        type: Number as PropType<number>,
        default: 0,
      },
      productforecast: {
        type: Number as PropType<number>,
        default: 0,
      },
      platformforecast: {
        type: Number as PropType<number>,
        default: 0,
      },
      hasplatformcost: {
        type: Boolean,
        default: false,
      },
      platformcost: {
        type: String,
        default: "",
      },
      budgetlimit: {
        type: Number as PropType<number>,
        default: 0,
      },
      target: {
        type: Number as PropType<number>,
        default: 0,
      },
    },
    computed: {
      sizeClass(): string {
        return `c-budgetsummary__financials-${this.size}`;
      },
      spacerSizeClass(): string {
        return `c-budgetsummary__spacer-${this.size}`;
      },
      isOverview(): string {
        if (this.size === "big") {
          return `c-budgetsummary__wrapper-overview`;
        } else {
          return "";
        }
      },
    },
    methods: {
      getColorClass(value: number | undefined, comparisonValue: number | undefined): string {
        if (value !== undefined && comparisonValue !== undefined) {
          if (value >= comparisonValue) return "c-budgetsummary__financials-negative";
          if (value < comparisonValue * 0.85) return "c-budgetsummary__financials-positive";
          if (value >= comparisonValue * 0.85) return "c-budgetsummary__financials-warning";
        }
        return "";
      },
    },

    setup() {
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const financialGoalLabel = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.financial_goal_label;
        } else {
          return "Budget";
        }
      });
      const financialHoverLabel = computed(() => {
        if (financialGoalLabel.value == "Budget") {
          return "Remaining budget";
        } else {
          return "Planned " + financialGoalLabel.value;
        }
      });
      return {
        formatNumber,
        currency,
        currencyRate,
        financialGoalLabel,
        financialHoverLabel,
      };
    },
  });
</script>
