import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-projapp" }
const _hoisted_2 = { class: "c-projapp__title" }
const _hoisted_3 = { "data-testid": "projapp-name" }
const _hoisted_4 = { class: "c-projapp__typeWrapper" }
const _hoisted_5 = {
  key: 0,
  class: "c-projapp__typeWrapper__consumer"
}
const _hoisted_6 = { class: "c-projapp__typeWrapper__type" }
const _hoisted_7 = { class: "c-projapp__owner-wrapper" }
const _hoisted_8 = { class: "c-projapp__button-row" }

import ProjectAppModal from "../modals/C_ProjectAppModal.vue";
  import { computed, watch, ref } from "vue";
  import { useStore } from "vuex";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { IUser } from "@/utils/interfaces/IUser";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_ProjAppCard',
  props: {
    projapp: {
      type: Object,
      required: true,
    },
  },
  emits: ["projapp-details"],
  setup(__props, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const ownerUsername = ref("");
  const isProjAppModalOpen = ref(false);

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (user.id === props.projapp.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  const handleModalVisibility = (visible: boolean) => {
    isProjAppModalOpen.value = visible;
  };

  const openProjAppModal = (id: number, type: string) => {
    emit("projapp-details", id, type);
    isProjAppModalOpen.value = true;
  };

  convertUsernameFromId();

  watch([users], () => {
    convertUsernameFromId();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.projapp.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (__props.projapp.platform_cost != null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, "C"))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, _toDisplayString(__props.projapp.type), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "c-projapp__owner-label" }, "Owner:", -1)),
        _createElementVNode("p", null, _toDisplayString(ownerUsername.value), 1)
      ])
    ]),
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(BudgetSummary, {
        size: "small",
        key: __props.projapp.id,
        budget: Number(__props.projapp.totalbudget),
        forecast: Number(__props.projapp.totalforecast),
        actuals: Number(__props.projapp.totalactuals),
        hasplatformcost: __props.projapp.platform_cost != null,
        platformcost: __props.projapp.platform_cost
      }, null, 8, ["budget", "forecast", "actuals", "hasplatformcost", "platformcost"]))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        type: "button",
        class: "cst-button-primary c-productcard__button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (openProjAppModal(__props.projapp.id, __props.projapp.type))),
        "data-testid": "projapp-details-btn"
      }, "Details")
    ]),
    _createVNode(ProjectAppModal, {
      projappName: __props.projapp.name,
      type: __props.projapp.type,
      "is-modal-enabled": isProjAppModalOpen.value,
      onIsProjectAppModalVisible: handleModalVisibility
    }, null, 8, ["projappName", "type", "is-modal-enabled"])
  ]))
}
}

})