import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "cst-new-product-type--wrapper",
  "data-testid": "financialsplanning-wrapper"
}
const _hoisted_2 = { class: "cst-inputbox" }
const _hoisted_3 = { class: "cst-inputbox--header" }
const _hoisted_4 = { class: "cst-inputbox--header__title" }
const _hoisted_5 = { class: "cst-inputbox--body" }
const _hoisted_6 = { class: "cst-inputbox__form" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "cst-input-field" }
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_12 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_13 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_14 = { class: "cst-input-field" }
const _hoisted_15 = { for: "financial-planning" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_18 = { class: "cst-input-field" }
const _hoisted_19 = {
  key: 0,
  class: "label-row-wrapper"
}
const _hoisted_20 = { class: "label-message" }

import { computed, ref } from "vue";
  import { onBeforeRouteLeave, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct, IProductFamily, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import { IUser } from "@/utils/interfaces/IUser";
  import C_FinancialRequestReview from "@/components/C_FinancialRequestReview.vue";

  interface FinancialPlanningFilter {
    id: string;
    label: string;
    options: { id: string; name: string }[];
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_FinancialsPlanning',
  setup(__props) {

  const { FETCH_FUTURE_PROPOSAL_FILTER, FETCH_FUTURE_DEMAND_FILTER, FETCH_SINGLE_PRODUCT, FETCH_SINGLE_PROJECT, FETCH_SINGLE_APP, FETCH_PRODUCT_FAMILY, CREATE_FUTURE_FINANCIALS_PLAN, FETCH_USERS } = VUEX_ACTIONS;

  const entities = ref<{ id: string; name: string }[]>([]);
  const selectedOption = ref<string>("");

  const router = useRouter();
  const store = useStore();
  const currentUser = computed(() => store.getters.getCurrentUser);
  const allUsers = computed(() => store.getters.getUsers);

  const currentUserId = computed(() => {
    const userId = allUsers.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const entityType = ref("");
  const selectedEntity = ref("");
  const description = ref("");
  const currentYearForecast = ref("");
  const lastYearActuals = ref("");
  const financials = ref<number>();
  const demandTitle = ref("");
  const demandId = ref("");

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);
  store.dispatch(FETCH_FUTURE_DEMAND_FILTER);

  const financialsFilter = computed(() => {
    if (router.currentRoute.value.path.toLowerCase() === "/proposal") {
      return store.getters.getProposalFilters;
    } else if (router.currentRoute.value.path.toLowerCase() === "/demand") {
      return store.getters.getDemandFilters;
    } else {
      return [];
    }
  });

  const filteredFilters = computed(() => {
    if (financialsFilter?.value.length > 0) {
      return financialsFilter.value.filter((filter: FinancialPlanningFilter) => filter.options.length > 0);
    } else {
      return [];
    }
  });

  const onOptionSelected = () => {
    selectedEntity.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    entities.value = [];
    store.commit("setProductFamily", []);
    store.commit("setProduct", []);
    store.commit("setSingleProject", []);
    store.commit("setSingleApplication", []);
    const selectedFilter = filteredFilters.value.find((filter: FinancialPlanningFilter) => filter.label === selectedOption.value);
    if (selectedFilter) {
      entities.value = selectedFilter.options;
      entityType.value = selectedFilter.label;
    }
  };

  const handleChangeEntity = async () => {
    store.commit("setProductFamily", []);
    store.commit("setProduct", []);
    store.commit("setSingleProject", []);
    store.commit("setSingleApplication", []);

    try {
      switch (entityType.value.toLowerCase()) {
        case "product family":
          await store.dispatch(FETCH_PRODUCT_FAMILY, selectedEntity.value);
          handleChangeData(store.getters.getProductFamily);
          break;
        case "product":
          await store.dispatch(FETCH_SINGLE_PRODUCT, selectedEntity.value);
          handleChangeData(store.getters.getProduct);
          break;
        case "project":
          await store.dispatch(FETCH_SINGLE_PROJECT, selectedEntity.value);
          handleChangeData(store.getters.getSingleProject);
          break;
        case "application":
          await store.dispatch(FETCH_SINGLE_APP, selectedEntity.value);
          handleChangeData(store.getters.getSingleApplication);
          break;
      }
    } catch (error) {
      console.error("Error fetching data for selected entity:", error);
    }
  };

  const handleChangeData = (entityData: IProduct | IProductFamily | IProjectApplication) => {
    if (entityData.lastyeartotalactuals) {
      lastYearActuals.value = formatNumber((Number(entityData.lastyeartotalactuals) * currencyRate.value).toFixed(2)) + " " + currency.value;
    }
    if ("forecast" in entityData && entityData.forecast !== undefined) {
      currentYearForecast.value = formatNumber((Number(entityData.forecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
    } else if ("totalforecast" in entityData && entityData.totalforecast !== undefined) {
      currentYearForecast.value = formatNumber((Number(entityData.totalforecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
    } else {
      currentYearForecast.value = "";
    }
  };

  // Submit button and POST new data
  const handleFinancialsRequest = () => {
    const payload = {
      description: description.value,
      entity_type: entityType.value.toLowerCase().split(" ").join(""),
      status: "proposed",
      sender_id: currentUserId.value,
    };

    if (router.currentRoute.value.path.toLowerCase() == "/proposal") {
      (payload as IFinancialPlan).proposed_budget = Number(financials.value);
      (payload as IFinancialPlan).proposed_by = currentUser.value;
    } else {
      (payload as IFinancialPlan).demand_title = demandTitle.value;
      (payload as IFinancialPlan).demand_id = demandId.value;
      (payload as IFinancialPlan).demanded_budget = financials.value;
    }

    switch (entityType.value.toLowerCase().split(" ").join("")) {
      case "productfamily":
        (payload as IFinancialPlan).entity_productfamily_id = Number(selectedEntity.value);
        break;
      case "product":
        (payload as IFinancialPlan).entity_product_id = Number(selectedEntity.value);
        break;
      case "project":
        (payload as IFinancialPlan).entity_project_id = Number(selectedEntity.value);
        break;
      case "application":
        (payload as IFinancialPlan).entity_application_id = Number(selectedEntity.value);
        break;
      default:
        console.error("Invalid entity type:", entityType.value);
    }

    store.dispatch(CREATE_FUTURE_FINANCIALS_PLAN, payload);
    demandTitle.value = "";
    demandId.value = "";
    financials.value = 0;
    description.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    selectedEntity.value = "";
    entityType.value = "";
  };

  onBeforeRouteLeave(() => {
    entityType.value = "";
    selectedEntity.value = "";
    selectedOption.value = "";
    lastYearActuals.value = "";
    currentYearForecast.value = "";
    entities.value = [];
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(router).currentRoute.value.path.toLowerCase() == "/proposal" ? `New ${financialGoalLabel.value} Proposal` : `New ${financialGoalLabel.value} Demand`), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(handleFinancialsRequest, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { for: "entity-type" }, [
                  _createTextVNode("Entity Type:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedOption).value = $event)),
                  onChange: onOptionSelected,
                  id: "filter-select",
                  "data-testid": "financial-planning-select-entity-type"
                }, [
                  _cache[8] || (_cache[8] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Select entity type", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFilters.value, (filter, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: filter.label,
                      "data-testid": "financial-planning-entity-type-option"
                    }, _toDisplayString(filter.label), 9, _hoisted_8))
                  }), 128))
                ], 544), [
                  [_vModelSelect, selectedOption.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { for: "entity" }, [
                  _createTextVNode("Entity:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives((_openBlock(), _createElementBlock("select", {
                  key: selectedOption.value,
                  class: "cst-select-field",
                  id: "entity",
                  required: "",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedEntity).value = $event)),
                  onChange: handleChangeEntity,
                  "data-testid": "financial-planning-select-entity"
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Select an entity", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entities.value, (entity) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: entity.id,
                      value: entity.id,
                      "data-testid": "financial-planning-entity"
                    }, _toDisplayString(entity.name), 9, _hoisted_10))
                  }), 128))
                ], 32)), [
                  [_vModelSelect, selectedEntity.value]
                ])
              ]),
              (_unref(router).currentRoute.value.path.toLowerCase() != '/proposal')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", { for: "demand-title" }, [
                      _createTextVNode(" Demand Title "),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "text",
                      id: "demand-title",
                      required: "",
                      placeholder: "Enter demand title",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((demandTitle).value = $event)),
                      maxlength: "32",
                      "data-testid": "financial-planning-input-demand-title"
                    }, null, 512), [
                      [_vModelText, demandTitle.value]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': demandTitle.value?.length == 32,
                }])
                    }, _toDisplayString(demandTitle.value?.length ? `${demandTitle.value.length}` : `0`) + "/32", 3)
                  ]))
                : _createCommentVNode("", true),
              (_unref(router).currentRoute.value.path.toLowerCase() != '/proposal')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _cache[13] || (_cache[13] = _createElementVNode("label", { for: "demand-id" }, [
                      _createTextVNode(" Demand ID "),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "text",
                      id: "demand-id",
                      required: "",
                      placeholder: "Enter demand ID",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((demandId).value = $event)),
                      maxlength: "12",
                      "data-testid": "financial-planning-input-demand-id"
                    }, null, 512), [
                      [_vModelText, demandId.value]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': demandId.value?.length == 12,
                }])
                    }, _toDisplayString(demandId.value?.length ? `${demandId.value.length}` : `0`) + "/12", 3)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (_unref(router).currentRoute.value.path.toLowerCase() == '/proposal')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _cache[14] || (_cache[14] = _createElementVNode("label", { for: "last-year-actuals" }, " Last Year Actuals ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "text",
                        id: "last-year-actuals",
                        disabled: "",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((lastYearActuals).value = $event)),
                        placeholder: "0,00 EUR",
                        "data-testid": "financial-planning-input-last-year-actuals"
                      }, null, 512), [
                        [_vModelText, lastYearActuals.value]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, [
                    _createTextVNode(_toDisplayString(_unref(router).currentRoute.value.path.toLowerCase() == "/proposal" ? `Proposed ${financialGoalLabel.value}` : "Demand Estimate"), 1),
                    _cache[15] || (_cache[15] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "number",
                    id: "financial-planning-estimate",
                    min: "1",
                    required: "",
                    placeholder: _unref(router).currentRoute.value.path == '/proposal' ? `Enter proposed ${financialGoalLabel.value}` : 'Enter demand estimate',
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((financials).value = $event)),
                    "data-testid": "financial-planning-estimate"
                  }, null, 8, _hoisted_16), [
                    [_vModelText, financials.value]
                  ])
                ])
              ]),
              _createElementVNode("div", null, [
                (_unref(router).currentRoute.value.path.toLowerCase() == '/proposal')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _cache[16] || (_cache[16] = _createElementVNode("label", { for: "current-year-forecast" }, " Current Year Forecast ", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "text",
                        id: "current-year-forecast",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((currentYearForecast).value = $event)),
                        disabled: "",
                        placeholder: "0,00 EUR",
                        "data-testid": "financial-planning-input-current-year-forecast"
                      }, null, 512), [
                        [_vModelText, currentYearForecast.value]
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_18, [
                  _cache[17] || (_cache[17] = _createElementVNode("label", { for: "description" }, "Description:", -1)),
                  _withDirectives(_createElementVNode("textarea", {
                    class: "cst-input cst-input__description",
                    type: "textarea",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((description).value = $event)),
                    id: "description",
                    maxlength: "240",
                    "data-testid": "financial-planning-input-description"
                  }, null, 512), [
                    [_vModelText, description.value]
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': description.value?.length == 240,
                  }])
                  }, _toDisplayString(description.value?.length ? `${description.value.length}` : `0`) + "/240", 3)
                ])
              ])
            ]),
            (_unref(router).currentRoute.value.path.toLowerCase() == '/demand')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "label" }, "Note:", -1)),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(financialGoalLabel.value) + " demand can only be created by the entity owner.", 1)
                ]))
              : _createCommentVNode("", true),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "cst-inputbox__actions" }, [
              _createElementVNode("div", { class: "cst-inputbox--flex-wrapper" }, [
                _createElementVNode("button", {
                  class: "submit-button cst-button cst-button-primary",
                  type: "submit",
                  "data-testid": "financial-planning-submit-button"
                }, "Submit")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ]),
    _createVNode(C_FinancialRequestReview, { financialGoalLabel: financialGoalLabel.value }, null, 8, ["financialGoalLabel"])
  ], 64))
}
}

})