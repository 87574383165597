import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "v-financialplanoverview__main-container",
  "data-testid": "financialplanoverview-wrapper"
}
const _hoisted_2 = { class: "v-polist__title-all" }
const _hoisted_3 = { class: "v-filter-products__toggle-view--financialplan-overview" }

import C_FinancialRequestReview from "@/components/C_FinancialRequestReview.vue";
  import C_FutureFinancialsTableOverview from "@/components/C_FutureFinancialsTableOverview.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ref, computed } from "vue";
  import { useStore } from "vuex";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_FinancialPlanOverview',
  setup(__props) {

  const { FETCH_USERS } = VUEX_ACTIONS;
  const store = useStore();
  const isGridView = ref(true);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  store.dispatch(FETCH_USERS);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(financialGoalLabel.value) + " Overview", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: './grid.svg',
        alt: "grid view",
        style: _normalizeStyle(isGridView.value ? { border: '1px solid #525252' } : ''),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isGridView.value = !isGridView.value)),
        "data-testid": "financialplanoverview-img-grid"
      }, null, 4),
      _createElementVNode("img", {
        src: './list.svg',
        alt: "list view",
        style: _normalizeStyle(!isGridView.value ? { border: '1px solid #525252' } : ''),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (isGridView.value = !isGridView.value)),
        "data-testid": "financialplanoverview-img-list"
      }, null, 4)
    ]),
    (isGridView.value)
      ? (_openBlock(), _createBlock(C_FinancialRequestReview, {
          key: 0,
          isoverview: true,
          financialGoalLabel: financialGoalLabel.value
        }, null, 8, ["financialGoalLabel"]))
      : _createCommentVNode("", true),
    (!isGridView.value)
      ? (_openBlock(), _createBlock(C_FutureFinancialsTableOverview, {
          key: 1,
          financialGoalLabel: financialGoalLabel.value
        }, null, 8, ["financialGoalLabel"]))
      : _createCommentVNode("", true)
  ]))
}
}

})