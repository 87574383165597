<template>
  <div v-if="hasPermission" class="v-createrequest__wrapper" data-testid="create-request-wrapper">
    <div class="v-createrequest__container">
      <div class="v-createrequest__title" data-testid="create-request-title">
        {{ router.currentRoute.value.path.toLowerCase() === "/renew-purchase-request" ? "Renew" : isEditingPr ? "Edit" : "Create" }}
        Purchase Request
      </div>

      <form class="v-createrequest__inputs-wrapper" @submit.prevent="handleSubmit">
        <div class="v-createrequest__inputs">
          <div class="v-createrequest__inputs-box">
            <h3>Request Info</h3>
            <div class="cst-input-field">
              <label for="date"><span class="cst-input-field--mandatory">* </span>Start Date:</label>
              <input class="cst-input" type="date" id="date" required data-testid="start-date" :min="currentDate" v-model="selectedStartDate" :disabled="isPrCreated && !isEditing" />

              <div class="cst-input-field">
                <label for="date">Delivery Date:</label>
                <input class="cst-input" type="date" id="date" data-testid="delivery-date" :min="currentDate" v-model="selectedEndDate" :disabled="isPrCreated && !isEditing" />
              </div>
              <div class="cst-input-field">
                <label for="ordertext"><span class="cst-input-field--mandatory">* </span>Order Text:</label>
                <input class="cst-input" type="text" id="ordertext" data-testid="order-text" required maxlength="50" v-model="orderText" :disabled="isPrCreated && !isEditing" />
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': orderText?.length == 50,
                  }"
                  >{{ orderText?.length ? `${orderText.length}` : `0` }}/50</span
                >
              </div>
              <div class="cst-input-field" v-if="supplierEnabled">
                <label for="supplier"><span class="cst-input-field--mandatory">* </span>Supplier:</label>
                <select class="cst-select-field" v-model="selectedSupplier" required :disabled="isPrCreated && !isEditing" data-testid="supplier">
                  <option disabled selected value="">Select a Supplier</option>
                  <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">
                    {{ supplier.name }}
                  </option>
                </select>
              </div>
              <div class="cst-input-field" v-if="sbuEnabled">
                <label for="sbu"><span class="cst-input-field--mandatory">* </span>SBU:</label>
                <select class="cst-select-field" v-model="selectedSBU" required :disabled="isPrCreated && !isEditing" data-testid="sbu">
                  <option disabled selected value="">Select SBU</option>
                  <option v-for="sbu in sbus" :key="sbu.id" :value="sbu.id">
                    {{ sbu.name }}
                  </option>
                </select>
              </div>
              <div class="cst-input-field" v-if="accountingElementEnabled">
                <label for="accelement"><span class="cst-input-field--mandatory">* </span>Accounting Element:</label>
                <input class="cst-input" type="text" id="accelement" required v-model="accountingElement" maxlength="16" :disabled="isPrCreated && !isEditing" />

                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': accountingElement?.length == 16,
                  }"
                  >{{ accountingElement?.length ? `${accountingElement.length}` : `0` }}/16</span
                >
              </div>
              <div class="cst-input-field">
                <label for="comment">Comment:</label>
                <input class="cst-input v-createrequest__comment" type="text" id="comment" v-model="comment" maxlength="90" :disabled="isPrCreated && !isEditing" />
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': comment?.length == 90,
                  }"
                  >{{ comment?.length ? `${comment.length}` : `0` }}/90</span
                >
              </div>
            </div>
          </div>
          <div class="v-createrequest__inputs-box">
            <h3>Enter Amount</h3>
            <div class="cst-input-field">
              <label for="orderamount"><span class="cst-input-field--mandatory">* </span>Order Amount:</label>
              <input class="cst-input" type="number" id="orderamount" v-model="orderAmount" required min="0" @input="preventDotInInput" :disabled="isPrCreated && !isEditing" data-testid="order-amount" />
            </div>
            <div class="cst-input-field">
              <label for="currency"><span class="cst-input-field--mandatory">* </span>Currency</label>
              <select class="cst-select-field" v-model="selectedCurrency" required :disabled="isPrCreated && !isEditing">
                <option v-for="currency in currencies" :key="currency.id" :value="currency.id" :selected="currency.id === selectedCurrency">
                  {{ currency.id }}
                </option>
              </select>
            </div>
          </div>
          <div class="v-createrequest__inputs-box">
            <h3>Add Entities</h3>
            <div class="cst-input-field">
              <label for="family"><span class="cst-input-field--mandatory">* </span>Product Family:</label>
              <select class="cst-select-field" v-model="selectedUserFamily" @change="handleSelectUserFamily($event)" :disabled="userFamilies.length === 0 || (isPrCreated && !isEditing)" data-testid="create-request-product-family-dropdown">
                <option disabled selected value="">Select a Product Family</option>
                <option v-for="family in userFamilies" :key="family.id" :value="family.id">
                  {{ family.name }}
                </option>
              </select>
            </div>
            <div class="cst-input-field">
              <label for="product"><span class="cst-input-field--mandatory">* </span>Product:</label>
              <select class="cst-select-field" :disabled="products.length === 0 || selectedUserFamily.length === 0 || (isPrCreated && !isEditing)" v-model="selectedProduct" @change="handleSelectProduct($event)" data-testid="create-request-product-dropdown">
                <option disabled selected value="">Select a Product</option>
                <option v-for="product in products" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>
            <div class="cst-input-field">
              <label for="project">Project:</label>
              <select class="cst-select-field" v-model="selectedProject" :disabled="projects.length === 0 || selectedProduct == '' || (isPrCreated && !isEditing)" @change="handleSelectProject($event)" data-testid="create-request-project-dropdown">
                <option disabled selected value="">Select a Project</option>
                <option v-for="project in projects" :key="project.id" :value="project.id">
                  {{ project.name }}
                </option>
              </select>
            </div>
            <div class="cst-input-field">
              <label for="application">Application:</label>
              <select
                class="cst-select-field"
                v-model="selectedApplication"
                :disabled="applications.length === 0 || selectedProduct == '' || (isPrCreated && !isEditing)"
                @change="handleSelectApplication($event)"
                data-testid="create-request-application-dropdown"
              >
                <option disabled selected value="">Select an Application</option>
                <option v-for="application in applications" :key="application.id" :value="application.id" :disabled="isPrCreated && !isEditing">
                  {{ application.name }}
                </option>
              </select>
            </div>
            <button type="button" class="cst-button cst-button-primary v-createrequest__addbtn" :disabled="selectedProduct == '' || (isPrCreated && !isEditing)" @click="handleAddButton" data-testid="create-request-add-button">Add</button>
          </div>
          <div class="v-createrequest__entitycards-wrapper">
            <h3>Selected entities:</h3>
            <div class="v-createrequest__entitycards" data-testid="createrequest-selected-entities">
              <div v-if="selectedEntities.length === 0" class="v-createrequest__warning" data-testid="create-request-warning">Please add at least 1 entity!</div>
              <div v-for="(entity, index) in selectedEntities" :key="index">
                <EntityCard :data="entity" @removeEntity="handleRemoveEntity" :isprcreated="isPrCreated" :isediting="isEditing"></EntityCard>
              </div>
            </div>
          </div>
        </div>

        <div class="v-createrequest__bottom-row">
          <p>
            Fields marked with
            <span class="cst-input-field--mandatory">* </span>are required.
          </p>
          <div class="v-createrequest__submit-btns">
            <button class="cst-button cst-button-primary v-createrequest__savebtn" v-if="isPrCreated" type="button" :disabled="isBtnNextDisabled || (!orderAmount && orderAmount <= 0)" @click="handleEdit" data-testid="create-request-edit-button">
              {{ !isEditing ? "Edit" : "Save" }}
            </button>
            <button
              type="submit"
              class="cst-button cst-button-primary v-createrequest__savebtn"
              :disabled="isBtnNextDisabled || (!orderAmount && orderAmount <= 0) || selectedEntities.length === 0 || isPrCreated"
              @click="scrollDown()"
              data-testid="create-request-next-button"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="v-createrequest__list-items--wrapper" v-if="isPrCreated && !isEditing">
      <div class="v-createrequest__container">
        <div class="v-createrequest__title">Add list items</div>
        <form class="v-createrequest__inputs-wrapper v-createrequest__list-items--menu" @submit.prevent="handleAddListItem">
          <div class="cst-input-field">
            <label for="entity"><span class="cst-input-field--mandatory">* </span>Entity:</label>
            <select class="cst-select-field" @change="handleSelectEntity" v-model="selectedEntity" data-testid="create-request-select-entity" required>
              <option disabled selected value="">Select Entity</option>
              <option v-for="entity in requestEntities" :key="entity" :value="entity.application ? entity.application : entity.project ? entity.project : entity.product">
                {{ entity.application ? findApplication(entity.application) : entity.project ? findProject(entity.project) : findProduct(entity.product) }}
              </option>
            </select>
          </div>
          <div class="cst-input-field">
            <label for="type"><span class="cst-input-field--mandatory">* </span>Type:</label>
            <select class="cst-select-field" required v-model="listItemData.type" @change="handleSelectType($event)" data-testid="create-request-select-type">
              <option disabled selected value="">Select a Type</option>
              <option v-if="undefinedEnabled" data-testid="create-request-type-undefined">Undefined</option>
              <option>Capital</option>
              <option>Expense</option>
            </select>
          </div>
          <div class="cst-input-field">
            <label for="costtype"><span class="cst-input-field--mandatory">* </span>Cost Type:</label>
            <select class="cst-select-field" required v-model="listItemData.costtype" @change="handleSelectCostType($event)" data-testid="create-request-select-cost-type">
              <option disabled selected value="">Select a Cost Type</option>
              <option>Infrastructure</option>
              <option>Maintenance</option>
              <option>Enhancement</option>
            </select>
          </div>
          <div class="cst-input-field v-createrequest__time-material">
            <input type="checkbox" id="timeAndMaterial" v-model="timeAndMaterialChecked" />
            <label for="timeAndMaterial">Time & Material</label>
          </div>

          <div>
            <div v-if="timeAndMaterialChecked">
              <div class="cst-input-field" style="margin-bottom: 0px">
                <label for="resourcename"><span class="cst-input-field--mandatory">* </span>Resource Name:</label>
                <input class="cst-input" type="text" v-model="listItemData.resourcename" id="resourcename" maxlength="32" required />
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': listItemData?.resourcename?.length == 32,
                  }"
                  >{{ listItemData.resourcename?.length ? `${listItemData?.resourcename?.length}` : `0` }}/32</span
                >
              </div>
              <div class="cst-input-field" style="margin-top: 0px">
                <label for="quantity"><span class="cst-input-field--mandatory">* </span>Person days:</label>
                <input class="cst-input" type="number" id="quantity" v-model="listItemData.quantity" required min="0.001" step="0.001" />
              </div>
            </div>
            <div class="cst-input-field" v-if="timeAndMaterialChecked">
              <label for="dailyrate"><span class="cst-input-field--mandatory">* </span>Daily Rate:</label>
              <input class="cst-input" type="number" id="dailyrate" required min="0.001" step="0.001" @input="preventDotInInput" v-model="listItemData.daily_rate" data-testid="daily-rate" />
            </div>
            <div class="cst-input-field" v-if="!timeAndMaterialChecked">
              <label for="amount"><span class="cst-input-field--mandatory">* </span>Amount:</label>
              <input class="cst-input" type="number" id="amount" required min="0" @input="preventDotInInput" v-model="listItemData.amount" data-testid="create-request-amount" />
            </div>
            <div class="cst-input-field">
              <label for="amount">Open Amount:</label>
              <input class="cst-input" type="text" id="amount" v-model="openAmountField" disabled data-testid="create-request-open-amount" />
            </div>
            <button type="submit" class="cst-button cst-button-primary" :disabled="isAmountExceeding && isPrAmountLimited" data-testid="create-request-add-list-item">Add</button>
          </div>
        </form>
      </div>

      <div class="v-createrequest__list-items--cards" v-if="listItems.length > 0 || listItemsFromEdit.length > 0">
        <div v-for="item in listItems" :key="item.name">
          <ListItemCard :data="item" @removeItem="handleRemoveListItem" :lastrequestcurrency="lastRequestCurrency"></ListItemCard>
        </div>
      </div>
    </div>
    <div class="v-createrequest__btns-wrapper" v-if="isPrCreated && !isEditing">
      <button
        type="button"
        class="cst-button cst-button-primary v-createrequest__btn"
        data-testid="create-request-save-btn"
        :disabled="(listItems.length === 0 && listItemsFromEdit.length === 0) || (openAmount !== 0 && isPrAmountLimited)"
        @click="handleSaveBtn"
      >
        {{ listItemsFromEdit.length !== 0 ? "Save" : "Send Request" }}
      </button>
      <button type="button" class="cst-button cst-button-danger v-createrequest__btn" @click="handleCancelButton">Cancel Request</button>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useStore } from "vuex";
  import { Ref, computed, onUnmounted, ref, watch } from "vue";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import ListItemCard from "@/components/cards/C_ListItemCard.vue";
  import EntityCard from "@/components/cards/C_EntityCard.vue";
  import { fetchTodaysDate } from "@/utils/helpers/fetchTodaysDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { useRouter } from "vue-router";
  import { formatDateForRequests } from "@/utils/helpers/formatDate";

  const {
    FETCH_APP_IN_PRODUCT,
    FETCH_PRODUCTS_IN_PROD_FAMILY,
    FETCH_PROJECTS_IN_PRODUCT,
    CREATE_PURCHASE_REQUEST,
    CHANGE_REQUEST_ENTITIES,
    CHANGE_PURCHASE_REQUEST_STATUS,
    FETCH_SBU,
    FETCH_SUPPLIERS,
    FETCH_ALL_PRODUCT_FAMILIES,
    FETCH_ALL_PRODUCTS,
    FETCH_PROJECTS,
    FETCH_APP,
    FETCH_PURCHASE_REQUESTS,
    CREATE_LIST_ITEM,
    DELETE_PURCHASE_REQUEST,
    MODIFY_PR_ORDER_AMOUNT,
  } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const suppliers = computed(() => store.getters.getSuppliers);
  const userFamilies = computed(() => store.getters.getAllProductFamilies);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const hasPermission = computed(() => userRoles.value.includes("edit-purchase-request"));
  const currencies = computed(() => store.getters.getAllCurrencies);
  const orderAmount = ref();
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const sbus = computed(() => store.getters.getSBU);
  const products = computed(() => store.getters.getProducts);
  const projects = computed(() => store.getters.getProductProjects);
  const requests = computed(() => store.getters.getPurchaseRequests);
  const lastRequest = computed(() => requests.value[requests.value.length - 1]);
  const lastRequestAmount = ref();
  const lastRequestCurrency = ref();
  const applications = computed(() => store.getters.getProductApplications);
  const allProducts = computed(() => store.getters.getAllProducts);
  const allApplications = computed(() => store.getters.getApplications);
  const allProjects = computed(() => store.getters.getProjects);
  const user = computed(() => store.getters.getCurrentUser);
  const requestEntities = computed(() => store.getters.getRequestEntities);
  const requestToEdit = computed(() => store.getters.getRequestToEdit);
  const isPrCreated = computed(() => store.getters.getIsPrCreated);
  const prIDFromArchive = ref("");
  const selectedCurrency = ref("EUR");
  const selectedUserFamily = ref("");
  const selectedProduct = ref("");
  const selectedApplication = ref("");
  const selectedProject = ref("");
  const currentDate = computed(() => fetchTodaysDate());
  const selectedEntity = ref("");
  const selectedStartDate = ref("");
  const selectedEndDate = ref("");
  const orderText = ref("");
  const comment = ref("");
  const accountingElement = ref("");
  const selectedSupplier = ref("");
  const selectedSBU = ref("");
  const entity = ref("");
  const openAmount = ref();
  const isAmountExceeding = ref<boolean>(false);
  const requestData: Ref<Partial<IPurchaseRequest>> = ref({});
  const openAmountField = computed(() => {
    if ((openAmount.value && isPrCreated.value) || isEditingPr.value) {
      return formatNumber(openAmount.value) + " " + selectedCurrency.value;
    } else {
      return 0 + " " + selectedCurrency.value;
    }
  });
  const totalListItemAmount = computed(() => {
    let total = 0;
    listItems.value.forEach((item) => {
      total += Number(item.amount);
    });
    return total;
  });

  const selectedEntities = ref<any[]>([]);
  const listItemData: Ref<IListItem> = ref({
    id: 0,
    name: "",
    type: "",
    costtype: "",
    amount: null,
    position: 0,
    currency: "",
    resourcename: "",
    quantity: null,
    daily_rate: null,
  });
  const timeAndMaterialChecked = ref(false);
  const isEditing = ref(false);
  const listItems: Ref<IListItem[]> = ref([]);
  const combined = ref<IListItem[]>([]);

  const isBtnNextDisabled = computed(() => {
    if (orderAmount.value && orderAmount.value < 1) {
      return true;
    } else {
      return false;
    }
  });
  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });
  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });
  const accountingElementEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_accounting_element;
    } else {
      return false;
    }
  });
  const undefinedEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.undefined_enabled;
    } else {
      return false;
    }
  });
  const isPrAmountLimited = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.is_pr_amount_limited;
    } else {
      return false;
    }
  });

  /* Verify these two are not needed, if they are not needed, remove them completely. */
  /* List items from edit/renew cannot be used due to the way our backend works */
  const listItemsFromEdit = ref([]);

  const combinedListItems = computed(() => {
    if (listItemsFromEdit.value.length > 0) {
      return listItems.value.concat(listItemsFromEdit.value);
    }
    return listItems.value;
  });

  const isEditingPr = computed(() => store.getters.getIsEditingPr);
  const isRenewingPr = computed(() => store.getters.getIsRenewingPr);

  store.dispatch(FETCH_SBU);
  store.dispatch(FETCH_SUPPLIERS);
  store.dispatch(FETCH_ALL_PRODUCT_FAMILIES);
  store.dispatch(FETCH_ALL_PRODUCTS);
  store.dispatch(FETCH_PROJECTS);
  store.dispatch(FETCH_APP);
  store.dispatch(FETCH_PURCHASE_REQUESTS);

  const handleSelectUserFamily = async (event: Event) => {
    selectedUserFamily.value = (event.target as HTMLSelectElement).value;
    await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedUserFamily.value);
    selectedProduct.value = "";
  };

  const handleSelectProduct = async (event: Event) => {
    selectedProduct.value = (event.target as HTMLSelectElement).value;
    await store.dispatch(FETCH_APP_IN_PRODUCT, selectedProduct.value);
    await store.dispatch(FETCH_PROJECTS_IN_PRODUCT, selectedProduct.value);
    selectedApplication.value = "";
    selectedProject.value = "";
  };

  const handleSelectProject = (event: Event) => {
    selectedProject.value = (event.target as HTMLSelectElement).value;
    selectedApplication.value = "";
  };

  const handleSelectApplication = (event: Event) => {
    selectedApplication.value = (event.target as HTMLSelectElement).value;
    selectedProject.value = "";
  };

  const handleSelectEntity = (event: Event) => {
    selectedEntity.value = (event.target as HTMLSelectElement).value;
    const isProject = findProject((event.target as HTMLSelectElement).value);
    const isApp = findApplication((event.target as HTMLSelectElement).value);
    if (isProject) {
      entity.value = "Project";
      listItemData.value.name = isProject;
    } else if (isApp) {
      entity.value = "Application";
      listItemData.value.name = isApp;
    } else {
      entity.value = "Product";
      listItemData.value.name = findProduct((event.target as HTMLSelectElement).value);
    }
  };

  const handleAddButton = () => {
    selectedEntities.value.push({
      product: selectedProduct.value ? Number(selectedProduct.value) : null,
      project: selectedProject.value ? Number(selectedProject.value) : null,
      application: selectedApplication.value ? Number(selectedApplication.value) : null,
    });
    selectedUserFamily.value = "";
    selectedProduct.value = "";
    selectedApplication.value = "";
    selectedProject.value = "";
  };

  const handleRemoveEntity = (id: number | string) => {
    const indexToRemove = selectedEntities.value.findIndex((entity) => {
      if (entity.application !== null) {
        return entity.application === id;
      } else if (entity.project !== null) {
        return entity.project === id;
      } else {
        return entity.product === Number(id);
      }
    });
    if (indexToRemove !== -1) {
      selectedEntities.value.splice(indexToRemove, 1);
    }
  };

  const handleRemoveListItem = (position: number) => {
    let removedItem = undefined;
    if (listItemsFromEdit.value.length !== 0) {
      removedItem = combinedListItems.value?.find((item) => item.position === position);
      combined.value = combined.value.filter((item) => item.position !== position);
      combined.value.forEach((item, index) => {
        item.position = index + 1;
      });
    } else {
      removedItem = listItems.value.find((item) => item.position === position);
      listItems.value = listItems.value.filter((item) => item.position !== position);
      listItems.value.forEach((item, index) => {
        item.position = index + 1;
      });
    }
    if (removedItem) {
      openAmount.value += parseFloat(removedItem.amount ? removedItem.amount.toString() : "");
    }
  };

  const handleSelectType = (event: Event) => {
    listItemData.value.type = (event.target as HTMLSelectElement).value;
    if (listItemData.value.type === "Undefined") {
      listItemData.value.costtype = "";
    }
  };

  const handleSelectCostType = (event: Event) => {
    listItemData.value.costtype = (event.target as HTMLSelectElement).value;
  };

  const preventDotInInput = () => {
    orderAmount.value = parseInt(orderAmount.value.toString().split(".").join(""));
  };

  const createPurchaseRequest = async () => {
    requestData.value = {
      podate: selectedStartDate.value,
      ordertext: orderText.value,
      accountingelement: accountingElement.value,
      owner: user.value,
      orderamount: Number(orderAmount.value),
      comments: comment.value,
      currency: selectedCurrency.value,
    };
    lastRequestCurrency.value = requestData.value.currency;
    if (selectedSupplier.value !== "") {
      requestData.value.supplier = Number(selectedSupplier.value);
    }
    if (selectedSBU.value !== "") {
      requestData.value.sbu = Number(selectedSBU.value);
    }
    if (selectedEndDate.value !== "") {
      requestData.value.deliverydate = selectedEndDate.value;
    }
  };

  const handleSubmit = async () => {
    if (selectedEntities.value.length > 0 && orderAmount.value > 0 && !isEditing.value) {
      createPurchaseRequest();
      if (router.currentRoute.value.path.toLowerCase() !== "/edit-purchase-request" && router.currentRoute.value.path.toLowerCase() !== "/renew-purchase-request") {
        await store.dispatch(CREATE_PURCHASE_REQUEST, requestData.value);
      } else if (router.currentRoute.value.path.toLowerCase() === "/renew-purchase-request") {
        store.commit("setIsPrCreated", true);
      }
      store.dispatch(CHANGE_REQUEST_ENTITIES, selectedEntities.value);
      setTimeout(() => scrollDown(), 200);
    }
  };

  const findProduct = (productID: string) => {
    const product = allProducts.value.filter((p: { id: string }) => p.id == productID);
    if (product.length !== 0) {
      return product[0].name;
    } else {
      return;
    }
  };

  const findProject = (projectID: string) => {
    const project = allProjects.value.filter((pr: { id: string }) => pr.id == projectID);
    if (project.length !== 0) {
      return project[0].name;
    } else {
      return;
    }
  };

  const findApplication = (appID: string) => {
    const app = allApplications.value.filter((a: { id: string }) => a.id == appID);
    if (app.length !== 0) {
      return app[0].name;
    } else {
      return;
    }
  };

  const handleAddListItem = () => {
    if (listItemData.value.amount !== null && listItemData.value.amount > lastRequestAmount.value) {
      return;
    }
    if (timeAndMaterialChecked.value) {
      if (listItemData.value.daily_rate && listItemData.value.quantity) {
        listItemData.value.amount = listItemData.value.quantity * listItemData.value.daily_rate;
      }
    }
    listItems.value.push({
      ...listItemData.value,
      position: listItems.value.length + 1,
      entity: entity.value,
      entityID: Number(selectedEntity.value),
    });
    openAmount.value -= Number(listItemData.value.amount);

    listItemData.value.amount = null;
    listItemData.value.costtype = "";
    listItemData.value.type = "";
    listItemData.value.name = "";
    listItemData.value.currency = selectedCurrency.value;
    listItemData.value.resourcename = "";
    listItemData.value.quantity = null;
    listItemData.value.daily_rate = null;
    selectedEntity.value = "";
  };

  const handleCancelButton = () => {
    setTimeout(() => {
      router.push("/requests");
    }, 500);
  };

  const handleSaveBtn = () => {
    const itemsToProcess = listItemsFromEdit.value.length > 0 ? combined.value : listItems.value;
    createPurchaseRequest();

    if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request" || router.currentRoute.value.path.toLowerCase() === "/renew-purchase-request") {
      requestData.value.orderamount = totalListItemAmount.value;
      store.dispatch(CREATE_PURCHASE_REQUEST, requestData.value);
    }

    if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request") {
      store.dispatch(CHANGE_PURCHASE_REQUEST_STATUS, {
        prID: Number(prIDFromArchive.value),
        status: "Declined",
        isJustification: false,
      });
    }

    /* Temporary solution, should be async and await the purchase request ID */
    setTimeout(() => {
      for (let item of itemsToProcess) {
        let typeId, type;

        if (listItems.value === itemsToProcess) {
          typeId = item.entityID;
          if (item.entity) {
            type = item.entity;
          }
        } else if (item.project !== null) {
          typeId = item.project;
          type = "Project";
        } else if (item.application !== null) {
          typeId = item.application;
          type = "Application";
        } else {
          typeId = item.product;
          type = "Product";
        }

        let itemType;
        if (item.type == "Undefined") {
          itemType = "Undefined";
        } else {
          itemType = item.type + " " + item.costtype;
        }

        const payload = {
          type: type,
          idoftype: Number(typeId),
          purchaserequest: listItemsFromEdit.value.length > 0 ? Number(item.id) : Number(lastRequest.value.id),
          position: Number(item.position),
          name: item.name,
          costtype: itemType,
          resourcename: item.resourcename,
          daily_rate: item.daily_rate ? Number(item.daily_rate) : null,
          quantity: item.quantity ? Number(item.quantity) : null,
          amount: Number(item.amount),
          currency: selectedCurrency.value,
        };
        store.dispatch(CREATE_LIST_ITEM, {
          requestID: listItemsFromEdit.value.length > 0 ? requestToEdit.value.id : lastRequest.value.id,
          payload: payload,
        });
        store.dispatch(MODIFY_PR_ORDER_AMOUNT, {
          id: listItemsFromEdit.value.length > 0 ? requestToEdit.value.id : lastRequest.value.id,
          orderamount: totalListItemAmount.value,
        });
      }
      setTimeout(() => {
        router.push("/requests");
      }, 500);
    }, 1500);
  };

  onUnmounted(() => {
    store.commit("setIsPrCreated", false);
    store.commit("setIsEditingPr", false);
    store.commit("setIsRenewingPr", false);
  });

  const handleEdit = () => {
    isEditing.value = !isEditing.value;
    listItems.value = [];
    const requestData: IPurchaseRequest = {
      podate: selectedStartDate.value,
      ordertext: orderText.value,
      accountingelement: accountingElement.value,
      owner: user.value,
      orderamount: Number(totalListItemAmount.value),
      comments: comment.value,
      currency: selectedCurrency.value,
    };
    if (selectedSupplier.value !== "") {
      requestData.supplier = Number(selectedSupplier.value);
    }
    if (selectedSBU.value !== "") {
      requestData.sbu = Number(selectedSBU.value);
    }
    if (selectedEndDate.value !== "") {
      requestData.deliverydate = selectedEndDate.value;
    }

    if (!isEditing.value && router.currentRoute.value.path.toLowerCase() !== "/edit-purchase-request" && router.currentRoute.value.path.toLowerCase() !== "/renew-purchase-request") {
      store.dispatch(DELETE_PURCHASE_REQUEST, lastRequest.value.id);
      store.dispatch(CREATE_PURCHASE_REQUEST, requestData);
    }
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight || document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  if (isEditingPr.value || isRenewingPr.value) {
    const retrievedDataString = localStorage.getItem("archiveItemEdit");
    if (retrievedDataString !== null) {
      const retrievedData = JSON.parse(retrievedDataString);
      prIDFromArchive.value = retrievedData.id;
      openAmount.value = retrievedData.orderamount;
      selectedStartDate.value = formatDateForRequests(retrievedData?.podate);
      selectedEndDate.value = formatDateForRequests(retrievedData?.deliverydate);
      orderText.value = retrievedData.ordertext;
      accountingElement.value = retrievedData?.accountingelement;
      selectedCurrency.value = retrievedData?.currency;
      comment.value = retrievedData?.comments;
      if (retrievedData?.supplier !== null && retrievedData?.supplier !== undefined) {
        selectedSupplier.value = retrievedData?.supplier;
      }
      if (retrievedData?.sbu !== null && retrievedData?.sbu !== undefined) {
        selectedSBU.value = retrievedData.sbu;
      }
      selectedEntities.value = retrievedData.listitems;
      orderAmount.value = retrievedData.orderamount;

      for (let item of listItems.value) {
        item.type = item.costtype.split(" ")[0];
        if (item.project) {
          item.entity = "Project";
          item.entityID = item.project;
        } else if (item.application) {
          item.entity = "Application";
          item.entityID = item.application;
        } else {
          item.entity = "Product";
          item.entityID = item.product;
        }
      }
      lastRequestCurrency.value = selectedCurrency.value;
      store.dispatch(CHANGE_REQUEST_ENTITIES, selectedEntities.value);
    }
  }

  if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request") {
    store.commit("setIsPrCreated", true);
  }

  watch([isPrCreated, lastRequest, isEditing], () => {
    if (isPrCreated.value) {
      openAmount.value = orderAmount.value;
    }
  });

  watch([() => listItemData.value.amount], () => {
    if (isPrAmountLimited.value) {
      if (Number(listItemData.value.amount) > openAmount.value) {
        isAmountExceeding.value = true;
      } else {
        isAmountExceeding.value = false;
      }
    }
  });

  if (!userRoles.value.includes("edit-purchase-request")) {
    router.push("/");
  }
</script>
