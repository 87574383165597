<template>
  <div class="c-productcard__wrapper c-productcard__wrapper--family">
    <div class="c-productcard__info">
      <div>
        <p class="c-productcard__product-name" data-testid="productfamily-name">{{ productfamily.name }}</p>
        <span class="c-productcard__family-name-tag">Family</span>
      </div>
    </div>

    <div class="c-projapp__owner-wrapper">
      <p class="c-projapp__owner-label">Owner:</p>
      <p>{{ ownerUsername }}</p>
    </div>
    <div class="c-productcard__summary" data-testid="div-budgetsummary">
      <BudgetSummary
        size="small"
        :budget="Number(productfamily.budget)"
        :budgetlimit="Number(productfamily.budget_limit)"
        :target="Number(productfamily.target)"
        :forecast="Number(productfamily.forecast) + Number(productfamily.total_product_forecast)"
        :productforecast="Number(productfamily.total_product_forecast)"
        :actuals="Number(productfamily.actuals) + Number(productfamily.total_product_actuals)"
      >
      </BudgetSummary>
      <div class="c-productcard__button-wrapper">
        <button type="button" class="cst-button-primary c-productcard__button" @click="handleOverviewClick" data-testid="pf-overview-button">Overview</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, defineProps, defineEmits } from "vue";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProductFamily } from "@/utils/interfaces/IProductTypes";

  const emit = defineEmits(["selected-pf"]);
  const props = defineProps<{
    productfamily: IProductFamily;
  }>();

  const store = useStore();

  const ownerUsername = ref("");
  const users = computed(() => store.getters.getUsers);

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (user.id === props.productfamily.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  convertUsernameFromId();

  const handleOverviewClick = () => {
    emit("selected-pf", props.productfamily);
  };
</script>
