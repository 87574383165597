import Keycloak from "keycloak-js";
import { ref } from "vue";
import { AXIOS_REQUEST } from "@/store/config";
import store from "./../store";
import { VUEX_ACTIONS } from "@/utils/constants";

const backendUrl = process.env.VUE_APP_KEYCLOAK_URL || "http://keycloak:8080";

/* eslint-disable */
const keycloakConfig = {
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  url: backendUrl,
  sslRequired: "external",
  resource: "frontend",
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  publicClient: true,
  confidentialPort: 0,
  redirectUri: window.location.origin + process.env.BASE_URL,
};

const keycloakInstance = new Keycloak(keycloakConfig);
const { UPDATE_CURRENT_USER, UPDATE_CURRENT_USER_ROLES, UPDATE_TOKEN, UPDATE_REFRESH_TOKEN, FETCH_CURRENCIES, FETCH_CONFIGURATION, FETCH_APP_VERSION, UPDATE_CURRENT_USER_GROUPS } = VUEX_ACTIONS;

type CallbackOneParam<T1 = void, T2 = void> = (param1: T1) => T2;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const loggedUser = ref(null);
const userRoles = ref([]);
store.dispatch(FETCH_CONFIGURATION);
store.dispatch(FETCH_APP_VERSION);

/*
   Keycloak instance MUST have pkceMethod: "S256" added once the server deployment has been fixed
   At the moment the backend URL is dynamic and we cannot handle it.
   Once the backend URL is on /api, we can return pkceMethod and configure keycloak for safer option
*/
const Login = (onAuthenticatedCallback: CallbackOneParam) => {
  keycloakInstance
    .init({ onLoad: "login-required" })
    .then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert("not authenticated");
      store.dispatch(UPDATE_TOKEN, keycloakInstance.token as string);
      store.dispatch(UPDATE_REFRESH_TOKEN, keycloakInstance.refreshToken as string);

      keycloakInstance.loadUserInfo().then(function (userInfo: any) {
        let roles;
        roles = userInfo.realm_access.roles.filter((role: string | any[]) => role !== "default-roles-coststeeringtool");
        store.dispatch(UPDATE_CURRENT_USER, userInfo.preferred_username);
        store.dispatch(UPDATE_CURRENT_USER_ROLES, roles);
        store.dispatch(UPDATE_CURRENT_USER_GROUPS, userInfo.group);

        AXIOS_REQUEST("POST", "/users/checkandcreate", {
          username: userInfo.preferred_username,
          roles: roles,
        });
        userRoles.value = roles;
        loggedUser.value = userInfo.preferred_username;
        store.dispatch(FETCH_CURRENCIES);
      });
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const Logout = () => {
  history.pushState({}, "", "/");
  keycloakInstance.logout();
};

const KeyCloakService = {
  CallLogin: Login,
  CallLogout: Logout,
  getUser: () => loggedUser.value,
  getRoles: () => userRoles.value,
};

export default KeyCloakService;
