import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { ICompanyContact } from "@/utils/interfaces/ICompanyContact";
import { handleAxiosError } from "@/utils/helpers/axiosErrorHandler";

interface CompanyContactState {
  allCompanyContacts: ICompanyContact[];
  companyContact: ICompanyContact | null;
  isCompanyContactCreated: boolean;
  createdCompanyContact: ICompanyContact | null;
}

interface CompanyContactContext {
  commit: Commit;
  dispatch: Dispatch;
  state: CompanyContactState;
}

const state: CompanyContactState = {
  allCompanyContacts: [],
  companyContact: null,
  isCompanyContactCreated: false,
  createdCompanyContact: null,
};

const mutations = {
  setAllCompanyContacts(state: CompanyContactState, payload: ICompanyContact[]) {
    state.allCompanyContacts = payload;
  },
  setCompanyContact(state: CompanyContactState, payload: ICompanyContact) {
    state.companyContact = payload;
  },
  setIsCompanyContactCreated(state: CompanyContactState, payload: boolean) {
    state.isCompanyContactCreated = payload;
  },
  setCreatedCompanyContact(state: CompanyContactState, payload: ICompanyContact) {
    state.createdCompanyContact = payload;
  },
};

const getters = {
  getAllCompanyContacts: (state: CompanyContactState) => state.allCompanyContacts,
  getCompanyContact: (state: CompanyContactState) => state.companyContact,
  getIsCompanyContactCreated: (state: CompanyContactState) => state.isCompanyContactCreated,
  getCreatedCompanyContact: (state: CompanyContactState) => state.createdCompanyContact,
};

const actions = {
  fetchAllCompanyContacts(context: CompanyContactContext) {
    AXIOS_REQUEST("GET", "/company-contacts")
      .then((response) => {
        context.commit("setAllCompanyContacts", response.data);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error fetching contacts");
        console.error(error);
      });
  },

  // Currently this route is not needed, but for future use we might need it for editing contacts
  fetchCompanyContact(context: CompanyContactContext, payload: { companyID: string; contactID: string }) {
    AXIOS_REQUEST("GET", `/companies/${payload.companyID}/company-contacts/${payload.contactID}`)
      .then((response) => {
        context.commit("setCompanyContact", response.data);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error fetching company contact");
        console.error(error);
      });
  },

  createCompanyContact(context: CompanyContactContext, contact: ICompanyContact) {
    AXIOS_REQUEST("POST", "/company-contacts", contact)
      .then((response) => {
        context.dispatch("updateRequestMessage", `SUCCESS: Company contact created!`);
        context.commit("setCreatedCompanyContact", response.data);
        context.commit("setIsCompanyContactCreated", true);
      })
      .catch((error) => {
        handleAxiosError(context, error, "Error creating company contact");
        console.error(error);
      });
  },
};

const companyContactModule = {
  state,
  getters,
  mutations,
  actions,
};

export default companyContactModule;
