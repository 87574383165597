<template>
  <div class="c-future-financials-modal--background">
    <div class="c-future-financials-modal">
      <div class="c-future-financials-modal__header">
        <h2>
          {{ props.entity?.entity_name ? props.entity.entity_name + ` Future ${financialGoalLabel} ` : `Future ${financialGoalLabel} ` }}
          Details
        </h2>
        <img src="../../../public/remove-white.svg" alt="close modal button" @click="closeModal()" data-testid="future-financials-closemodal-button" />
      </div>
      <div class="c-future-financials-modal__container">
        <div class="c-future-financials-modal__container__labels">
          <p>Date</p>
          <p>Sender</p>
          <p class="c-future-financials-modal__container__labels--message">Message</p>
        </div>
        <div class="c-future-financials-modal__container__messages">
          <div v-if="specificFutureFinancials">
            <div v-for="comment in specificFutureFinancials.comments" v-bind:key="comment.id" class="c-future-financials-modal__container__messages--message">
              <div>
                <p>{{ formatDateWithTime(comment.time) }}</p>
              </div>
              <p>{{ convertUsernameFromId(comment.sender_id) }}</p>
              <p class="c-future-financials-modal__container__labels--message">
                {{ comment.message }}
              </p>
            </div>
          </div>
          <div v-else class="cst-spinner"></div>
        </div>
      </div>
      <div class="c-future-financials-modal__footer">
        <div class="cst-input-field">
          <label for="comment">Comment:</label>
          <textarea class="cst-input c-future-financials-modal__messagebox" type="textarea" id="comment" maxlength="240" v-model="message"></textarea>
          <span
            class="cst-input-field__indicator"
            :class="{
              'cst-input-field__indicator--max': message?.length == 240,
            }"
            >{{ message?.length ? `${message.length}` : `0` }}/240</span
          >
        </div>
        <button type="button" class="cst-button-primary c-productcard__button" @click="handleAddComment">Add</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import { formatDateWithTime } from "@/utils/helpers/formatDate";
  import { defineProps, onBeforeUnmount, onMounted, defineEmits, ref, computed } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";
  const { CREATE_FINANCIAL_PLAN_COMMENT } = VUEX_ACTIONS;
  const props = defineProps<{
    entity: IFinancialPlan;
    financialGoalLabel?: string;
  }>();
  const emit = defineEmits(["close-details-modal"]);
  const store = useStore();
  const specificFutureFinancials = computed(() => store.getters.getSpecificFutureFinancials);
  const message = ref("");
  const users = computed(() => store.getters.getUsers);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const currentUserId = computed(() => {
    const userId = users.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });

  const closeModal = () => {
    store.commit("setSpecificFutureFinancials", null);
    emit("close-details-modal");
  };

  const convertUsernameFromId = (username: string) => {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  };
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const handleAddComment = () => {
    const payload = {
      sender_id: Number(currentUserId.value),
      future_budget_id: Number(props.entity.id),
      message: message.value,
    };
    store.dispatch(CREATE_FINANCIAL_PLAN_COMMENT, payload);
    message.value = "";
  };

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });
</script>
