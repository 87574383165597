<template>
  <div class="c-projapp">
    <div class="c-projapp__title">
      <p data-testid="projapp-name">{{ projapp.name }}</p>
      <div class="c-projapp__typeWrapper">
        <div v-if="projapp.platform_cost != null" class="c-projapp__typeWrapper__consumer">C</div>
        <div class="c-projapp__typeWrapper__type">{{ projapp.type }}</div>
      </div>
      <div class="c-projapp__owner-wrapper">
        <p class="c-projapp__owner-label">Owner:</p>
        <p>{{ ownerUsername }}</p>
      </div>
    </div>

    <div>
      <BudgetSummary
        size="small"
        :key="projapp.id"
        :budget="Number(projapp.totalbudget)"
        :forecast="Number(projapp.totalforecast)"
        :actuals="Number(projapp.totalactuals)"
        :hasplatformcost="projapp.platform_cost != null"
        :platformcost="projapp.platform_cost"
      />
    </div>
    <div class="c-projapp__button-row">
      <button type="button" class="cst-button-primary c-productcard__button" @click="openProjAppModal(projapp.id, projapp.type)" data-testid="projapp-details-btn">Details</button>
    </div>
    <ProjectAppModal :projappName="projapp.name" :type="projapp.type" :is-modal-enabled="isProjAppModalOpen" @isProjectAppModalVisible="handleModalVisibility"></ProjectAppModal>
  </div>
</template>
<script setup lang="ts">
  import ProjectAppModal from "../modals/C_ProjectAppModal.vue";
  import { defineProps, computed, watch, ref, defineEmits } from "vue";
  import { useStore } from "vuex";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { IUser } from "@/utils/interfaces/IUser";

  const props = defineProps({
    projapp: {
      type: Object,
      required: true,
    },
  });
  const emit = defineEmits(["projapp-details"]);
  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const ownerUsername = ref("");
  const isProjAppModalOpen = ref(false);

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (user.id === props.projapp.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  const handleModalVisibility = (visible: boolean) => {
    isProjAppModalOpen.value = visible;
  };

  const openProjAppModal = (id: number, type: string) => {
    emit("projapp-details", id, type);
    isProjAppModalOpen.value = true;
  };

  convertUsernameFromId();

  watch([users], () => {
    convertUsernameFromId();
  });
</script>
