import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, unref as _unref } from "vue"
import _imports_0 from '../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "v-createinvoice",
  "data-testid": "create-invoice"
}
const _hoisted_2 = { class: "cst-inputbox cst-inputbox__choose-items" }
const _hoisted_3 = { class: "cst-inputbox__invoice-item" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "v-createinvoice__li-container" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  for: "quantity"
}
const _hoisted_8 = {
  key: 3,
  class: ""
}
const _hoisted_9 = { key: 4 }
const _hoisted_10 = {
  key: 5,
  class: "v-createinvoice__available-amount"
}
const _hoisted_11 = { class: "v-createinvoice__available-amount" }
const _hoisted_12 = { class: "v-createinvoice__button-wrapper" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "v-createinvoice__invoice-wrapper" }
const _hoisted_16 = { class: "v-createinvoice__invoice-title" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "v-createinvoice__invoice-date" }
const _hoisted_19 = { class: "v-createinvoice__invoice-date" }
const _hoisted_20 = ["disabled", "min", "max"]
const _hoisted_21 = {
  key: 1,
  class: "v-createinvoice__invoice-date"
}
const _hoisted_22 = ["min"]
const _hoisted_23 = { class: "v-createinvoice__table-content" }
const _hoisted_24 = { class: "v-createinvoice__table-row" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "v-createinvoice__totalamount" }
const _hoisted_27 = { class: "v-createinvoice__button-wrapper" }
const _hoisted_28 = ["disabled"]

import { computed, ref, Ref, watchEffect } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { IListItem, INewInvoiceListItem } from "@/utils/interfaces/IListItem";
  import { IOrderListItem, IInvoiceListItem } from "@/utils/interfaces/IInvoice";
  import { fetchTodaysDate } from "@/utils/helpers/fetchTodaysDate";
  import { getLastDayOfMonth } from "@/utils/helpers/calculateLastDayOfMonth";
  import { VUEX_ACTIONS } from "@/utils/constants";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreateInvoice',
  setup(__props) {

  const { FETCH_NOTIFICATIONS, CREATE_INVOICE } = VUEX_ACTIONS;

  const store = useStore();
  const router = useRouter();
  const listItemsFromOrder: Ref<IOrderListItem[]> = ref([]);
  const createInvoiceFromOrder = computed(() => store.getters.getInvoiceFromOrder);
  const selectedItemId = ref();
  const showSelectedItemBlock = ref(true);
  const currentDate = computed(() => fetchTodaysDate());
  const startDate = ref("");
  const endDate = ref("");
  const selectedDate = ref("");
  const localOrderListItems = ref();
  const invoiceTitle = ref("");
  const selectedItem = computed(() => {
    if (localOrderListItems.value) {
      return localOrderListItems.value.find((item: IListItem) => item.id === selectedItemId.value);
    } else return null;
  });
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const createInvoiceEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.create_invoice_enabled;
    } else {
      return false;
    }
  });
  const dateRangeEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoice_has_date_range;
    } else {
      return false;
    }
  });

  const invoiceListItemData: Ref<IOrderListItem> = ref({
    amount: null,
    currency: "",
    resource_name: "",
    quantity: null,
    daily_rate: null,
    po_listitem_id: null,
    li_name: "",
  });

  const handleSelectListItem = () => {
    if (selectedItem.value) {
      invoiceListItemData.value.li_name = selectedItem.value.name;
      invoiceListItemData.value.po_listitem_id = selectedItem.value.id;
      invoiceListItemData.value.resource_name = selectedItem.value.resourcename;
      invoiceListItemData.value.quantity = selectedItem.value.quantity;
      invoiceListItemData.value.daily_rate = selectedItem.value.daily_rate;
      invoiceListItemData.value.currency = selectedItem.value.currency;
      showSelectedItemBlock.value = true;
      invoiceListItemData.value.amount = null;
    }
  };

  const preventDotInInput = () => {
    if (invoiceListItemData.value.amount !== null) {
      invoiceListItemData.value.amount = parseInt(invoiceListItemData.value.amount.toString().split(".").join(""));
    }
  };

  const handleAddToInvoice = () => {
    if (invoiceListItemData.value.amount && invoiceListItemData.value.amount > 0) {
      invoiceListItemData.value.quantity = null;
      listItemsFromOrder.value.push({
        ...invoiceListItemData.value,
      });
    } else if (totalListItemAmount.value > 0) {
      invoiceListItemData.value.amount = totalListItemAmount.value;
      listItemsFromOrder.value.push({
        ...invoiceListItemData.value,
      });
    }
    const filteredArray = localOrderListItems.value.filter((item: INewInvoiceListItem) => Number(item.id) !== Number(invoiceListItemData.value.po_listitem_id));

    localOrderListItems.value = filteredArray;
    showSelectedItemBlock.value = false;
    invoiceListItemData.value.amount = null;
  };

  const totalListItemAmount = computed(() => {
    if (invoiceListItemData.value.quantity && invoiceListItemData.value.daily_rate) {
      let listItemAmount = invoiceListItemData.value.quantity * invoiceListItemData.value.daily_rate;
      return listItemAmount;
    } else if (invoiceListItemData.value.amount) {
      return invoiceListItemData.value.amount;
    } else {
      return 0;
    }
  });

  const totalInvoiceAmount = computed(() => {
    let total = 0;
    for (const item of listItemsFromOrder.value) {
      if (item.amount !== null && item.amount !== undefined) {
        total += item.amount;
      }
    }
    return total + " EUR";
  });

  const createInvoice = () => {
    const invoiceListItems: IInvoiceListItem[] = listItemsFromOrder.value.map((item) => {
      // li_name was removed from the invoice because it doesn't match response on backend
      const { li_name, ...invoiceItem } = item; // eslint-disable-line @typescript-eslint/no-unused-vars
      return invoiceItem;
    });
    const invoice = {
      name: invoiceTitle.value,
      date: dateRangeEnabled.value ? currentDate.value : selectedDate.value,
      start_date: dateRangeEnabled.value ? startDate.value : null,
      end_date: dateRangeEnabled.value ? endDate.value : null,
      purchaseorder_id: Number(createInvoiceFromOrder.value.id),
      listitems: invoiceListItems,
    };
    store.dispatch(CREATE_INVOICE, invoice);
    setTimeout(() => {
      router.push("/invoices");
    }, 500);
  };

  localOrderListItems.value = createInvoiceFromOrder?.value?.listitems;
  const removeListItemFromInvoice = (itemId: number | null) => {
    for (const item of createInvoiceFromOrder.value.listitems) {
      if (item.id === itemId) {
        localOrderListItems.value.push(item);
        const filteredArray = listItemsFromOrder.value.filter((item: IOrderListItem) => item.po_listitem_id !== itemId);
        listItemsFromOrder.value = filteredArray;
        selectedItemId.value = itemId;
        handleSelectListItem();
      }
    }
  };

  watchEffect(() => {
    if (invoiceListItemData.value.amount && invoiceListItemData.value.amount > selectedItem.value.li_remaining_amount) {
      invoiceListItemData.value.amount = selectedItem.value.li_remaining_amount;
    }
  });

return (_ctx: any,_cache: any) => {
  return (createInvoiceEnabled.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
            _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Choose list items")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _cache[8] || (_cache[8] = _createElementVNode("label", {
                for: "listItems",
                "data-testid": "list-item-label"
              }, [
                _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                _createTextVNode("List Item:")
              ], -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field cst-select-field--list-item",
                id: "listItems",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedItemId).value = $event)),
                onChange: handleSelectListItem,
                "data-testid": "list-items-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localOrderListItems.value, (listItem) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: listItem.id,
                    value: listItem.id,
                    "data-testid": "list-item-option"
                  }, _toDisplayString(listItem.name) + " - " + _toDisplayString(listItem.costtype), 9, _hoisted_4))
                }), 128))
              ], 544), [
                [_vModelSelect, selectedItemId.value]
              ])
            ]),
            (selectedItem.value && showSelectedItemBlock.value)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  onSubmit: _withModifiers(handleAddToInvoice, ["prevent"]),
                  "data-testid": "add-to-invoice-form"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (selectedItem.value.resourcename?.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                          _cache[9] || (_cache[9] = _createElementVNode("span", null, "Resource Name: ", -1)),
                          _createTextVNode(_toDisplayString(selectedItem.value.resourcename), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (selectedItem.value.resourcename?.length > 0)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_7, _cache[10] || (_cache[10] = [
                          _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* ", -1),
                          _createTextVNode("Person days:")
                        ])))
                      : _createCommentVNode("", true),
                    (selectedItem.value.resourcename?.length > 0)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 2,
                          class: "cst-input",
                          type: "number",
                          id: "quantity",
                          "data-testid": "person-days-input",
                          required: "",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((invoiceListItemData.value.quantity) = $event)),
                          min: "0"
                        }, null, 512)), [
                          [_vModelText, invoiceListItemData.value.quantity]
                        ])
                      : _createCommentVNode("", true),
                    (selectedItem.value.daily_rate !== null)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                          _cache[11] || (_cache[11] = _createElementVNode("span", null, "Daily Rate: ", -1)),
                          _createTextVNode(_toDisplayString(selectedItem.value.daily_rate), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (selectedItem.value.daily_rate == null)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", { for: "amount" }, [
                            _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                            _createTextVNode("Amount:")
                          ], -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "cst-input",
                            type: "number",
                            id: "amount",
                            "data-testid": "amount-input",
                            required: "",
                            onInput: preventDotInInput,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((invoiceListItemData.value.amount) = $event))
                          }, null, 544), [
                            [_vModelText, invoiceListItemData.value.amount]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (totalListItemAmount.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[13] || (_cache[13] = _createElementVNode("p", null, "Total Amount:", -1)),
                          _createElementVNode("span", null, _toDisplayString(totalListItemAmount.value + " " + selectedItem.value.currency), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[14] || (_cache[14] = _createElementVNode("p", null, "Available amount:", -1)),
                      _createElementVNode("span", null, _toDisplayString(selectedItem.value.li_remaining_amount + " " + selectedItem.value.currency), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("button", {
                        type: "submit",
                        class: "submit-button cst-button cst-button-primary",
                        disabled: totalListItemAmount.value > selectedItem.value.li_remaining_amount || !totalListItemAmount.value || totalListItemAmount.value <= 0,
                        "data-testid": "add-to-invoice-button"
                      }, " Add to Invoice ", 8, _hoisted_13)
                    ])
                  ])
                ], 32))
              : (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[15] || (_cache[15] = [
                  _createElementVNode("p", null, "Please choose the list item you want to add to the invoice.", -1)
                ])))
          ])
        ]),
        _createElementVNode("form", {
          class: "cst-inputbox cst-inputbox__create-invoice",
          onSubmit: _withModifiers(createInvoice, ["prevent"])
        }, [
          _createElementVNode("div", null, [
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
              _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Create Invoice")
            ], -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", null, [
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "Order name: ", -1)),
                _createTextVNode(_toDisplayString(createInvoiceFromOrder.value.ordertext), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[18] || (_cache[18] = _createElementVNode("span", null, "Customer contact: ", -1)),
                _createTextVNode(_toDisplayString(createInvoiceFromOrder.value.customercontact), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "title" }, [
                  _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                  _createTextVNode("Invoice Title:")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "text",
                  id: "title",
                  required: "",
                  onInput: preventDotInInput,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((invoiceTitle).value = $event)),
                  "data-testid": "invoice-title-input"
                }, null, 544), [
                  [_vModelText, invoiceTitle.value]
                ])
              ]),
              (dateRangeEnabled.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", { for: "start-date" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                        _createTextVNode(" Invoice Start Date:")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "date",
                        id: "start-date",
                        "data-testid": "invoice-date-input-start",
                        required: "",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((startDate).value = $event)),
                        onInput: _cache[5] || (_cache[5] = ($event: any) => (endDate.value = ''))
                      }, null, 544), [
                        [_vModelText, startDate.value]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[21] || (_cache[21] = _createElementVNode("label", { for: "end-date" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                        _createTextVNode(" Invoice End Date:")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "date",
                        id: "end-date",
                        "data-testid": "invoice-date-input-end",
                        disabled: startDate.value === '',
                        required: "",
                        min: startDate.value,
                        max: startDate.value ? _unref(getLastDayOfMonth)(startDate.value) : '',
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((endDate).value = $event))
                      }, null, 8, _hoisted_20), [
                        [_vModelText, endDate.value]
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _cache[22] || (_cache[22] = _createElementVNode("label", { for: "date" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode(" Invoice Date:")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "date",
                      id: "date",
                      "data-testid": "invoice-date-input",
                      required: "",
                      min: currentDate.value,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((selectedDate).value = $event))
                    }, null, 8, _hoisted_22), [
                      [_vModelText, selectedDate.value]
                    ])
                  ])),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "v-createinvoice__table-header" }, [
                _createElementVNode("p", null, "No."),
                _createElementVNode("p", null, "Name"),
                _createElementVNode("p", null, "Resource"),
                _createElementVNode("p", null, "Person Days"),
                _createElementVNode("p", null, "Amount"),
                _createElementVNode("p", null, "Remove")
              ], -1)),
              _createElementVNode("div", _hoisted_23, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listItemsFromOrder.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.li_name
                  }, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("p", null, _toDisplayString(index + 1), 1),
                      _createElementVNode("p", null, _toDisplayString(item.li_name), 1),
                      _createElementVNode("p", null, _toDisplayString(item.resource_name), 1),
                      _createElementVNode("p", null, _toDisplayString(item.quantity), 1),
                      _createElementVNode("p", null, _toDisplayString(item.amount + " " + item.currency), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "",
                          "data-testid": "remove-invoice-item",
                          onClick: ($event: any) => (removeListItemFromInvoice(item.po_listitem_id))
                        }, null, 8, _hoisted_25)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("p", null, [
                  _cache[23] || (_cache[23] = _createElementVNode("span", null, "Total Amount:", -1)),
                  _createTextVNode(" " + _toDisplayString(totalInvoiceAmount.value), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("button", {
              type: "submit",
              class: "submit-button cst-button cst-button-primary",
              "data-testid": "create-invoice-btn",
              disabled: listItemsFromOrder.value.length < 1
            }, "Create Invoice", 8, _hoisted_28)
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}
}

})