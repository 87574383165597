<template>
  <div class="cst-new-product-type--wrapper" v-if="futureFinancials.length > 0" data-testid="financialrequestreview-wrapper">
    <div class="cst-inputbox" :class="{ 'c-financialrequestreview--overview': props.isoverview }" data-testid="financialrequestreview-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title" data-testid="financialrequestreview-inputbox-header">
          {{ props.isoverview ? `Approved Future ${financialGoalLabel} Entities` : "Requests In Review" }}
        </div>
      </div>
      <C_Search @input="filterFutureFinancialPlans($event)" :placeholder="`Search future ${financialGoalLabel}...`" />
      <div
        class="c-financialrequestreview--wrapper"
        data-testid="financialrequestreview-card-wrapper"
        :class="{
          'c-financialrequestreview--wrapper--overview': props.isoverview,
        }"
      >
        <div class="c-financialrequestreview" v-for="entity in filteredFinancialPlan" :key="entity.id">
          <C_FinancialsCard
            @future-financials-details="handleFutureFinancialsDetails"
            @future-financials-approve="handleFutureFinancialsApproval"
            @future-financials-decline="handleFutureFinancialsDecline"
            :entity="entity"
            :isoverview="props.isoverview"
            :financialGoalLabel="financialGoalLabel"
          />
        </div>
        <div class="c-financialrequestreview" v-if="filteredFinancialPlan.length === 0" data-testid="financialrequestreview-no-such-entities">There are no such entities.</div>
      </div>
    </div>
  </div>
  <div v-else-if="isoverview && areFutureFinancialsFetched" class="c-financialrequestreview--empty" data-testid="financialrequestreview-empty">No approved future financial goal requests available.</div>
  <div v-else-if="!areFutureFinancialsFetched" data-testid="financialrequestreview-spinner" class="cst-spinner"></div>
  <C_FutureFinancialsModal v-if="showDetailsModal" @close-details-modal="showDetailsModal = !showDetailsModal" :entity="selectedEntity" :financialGoalLabel="financialGoalLabel"></C_FutureFinancialsModal>
  <C_FutureFinancialsDecisionModal v-if="showDecisionModal" :entity="selectedEntity" @close-decision-modal="showDecisionModal = !showDecisionModal" :is-approval="isApproval" :financialGoalLabel="financialGoalLabel"> </C_FutureFinancialsDecisionModal>
</template>
<script lang="ts" setup>
  import { computed, ref, defineProps, watch } from "vue";
  import { useStore } from "vuex";
  import C_FinancialsCard from "./cards/C_FinancialsCard.vue";
  import C_FutureFinancialsModal from "./modals/C_FutureFinancialsModal.vue";
  import C_FutureFinancialsDecisionModal from "./modals/C_FutureFinancialsDecisionModal.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import C_Search from "./C_Search.vue";

  const { FETCH_FUTURE_FINANCIALS, FETCH_SPECIFIC_FUTURE_FINANCIALS, FETCH_FUTURE_PROPOSAL_FILTER } = VUEX_ACTIONS;
  const props = defineProps({
    isoverview: Boolean,
    financialGoalLabel: String,
  });
  const store = useStore();
  const futureFinancials = computed(() => {
    const financialGoal = store.getters.getFutureFinancials;
    if (props.isoverview) {
      return financialGoal.filter((item: IFinancialPlan) => item.status == "approved");
    } else {
      return financialGoal.filter((item: IFinancialPlan) => item.status !== "approved");
    }
  });
  const filteredFinancialPlan = ref(futureFinancials.value);
  const areFutureFinancialsFetched = computed(() => store.getters.getFutureFinancialsFetched);
  const selectedEntity = ref<IFinancialPlan>({});
  const showDetailsModal = ref(false);
  const showDecisionModal = ref(false);
  const isApproval = ref(false);
  store.dispatch(FETCH_FUTURE_FINANCIALS);

  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);

  const handleFutureFinancialsDetails = (entity: IFinancialPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_FINANCIALS, selectedEntity.value.id);
  };

  const handleFutureFinancialsApproval = (entity: IFinancialPlan) => {
    isApproval.value = true;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const handleFutureFinancialsDecline = (entity: IFinancialPlan) => {
    isApproval.value = false;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const filterFutureFinancialPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredFinancialPlan.value = futureFinancials.value.filter((financial_goal: IFinancialPlan) => {
      if (financial_goal?.entity_name) return financial_goal?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  watch([futureFinancials], () => {
    filteredFinancialPlan.value = futureFinancials.value;
  });
</script>
