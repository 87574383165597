import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "c-prodprojectappmodal__wrapper"
}
const _hoisted_2 = { class: "c-prodprojectappmodal__container" }
const _hoisted_3 = { class: "c-prodprojectappmodal__headline" }
const _hoisted_4 = {
  class: "c-prodprojectappmodal__title",
  "data-testid": "projectapp-modal-title"
}
const _hoisted_5 = { class: "c-prodprojectappmodal__content" }
const _hoisted_6 = { class: "c-prodprojectappmodal__data" }
const _hoisted_7 = { class: "c-prodprojectappmodal__table" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "v-showinvoices__table--overview",
  "aria-describedby": "Table of invoices"
}
const _hoisted_10 = { class: "v-showinvoices__table-head-row" }
const _hoisted_11 = { class: "v-showinvoices__table-row" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { colspan: "13" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "v-showinvoices__listitems--overview" }
const _hoisted_17 = { class: "v-showinvoices__li-table--wrapper" }
const _hoisted_18 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_19 = { class: "v-showinvoices__li-table-row--first" }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "v-showinvoices__listitems--invoices" }
const _hoisted_22 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "vlistpo-spinner"
}
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "v-showinvoices__li-table--wrapper" }
const _hoisted_25 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { class: "v-showinvoices__listitems--invoices" }
const _hoisted_29 = { class: "v-showinvoices__li-table--wrapper" }
const _hoisted_30 = {
  key: 0,
  class: "v-showinvoices__li-invoice-details v-showinvoices__li-table-row"
}
const _hoisted_31 = { class: "v-showinvoices__li-invoice-details--first" }
const _hoisted_32 = { class: "v-showinvoices__listitems--remaining" }
const _hoisted_33 = {
  key: 2,
  class: "v-showinvoices__li-table--noinvoices"
}
const _hoisted_34 = {
  key: 1,
  class: "c-prodprojectappmodal__no-orders"
}
const _hoisted_35 = {
  key: 1,
  class: "cst-spinner"
}

import { IInvoice, IInvoiceFromPO } from "@/utils/interfaces/IInvoice";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { Ref, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_ProjectAppModal',
  props: {
    projappName: { type: String, default: "" },
    type: { type: String, default: "project" },
    isModalEnabled: { type: Boolean, default: false },
    isplatform: { type: Boolean, default: false },
  },
  emits: ["isProjectAppModalVisible"],
  setup(__props, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const { UPDATE_PO_LIST_FETCHED_STATE, FETCH_INVOICES_FROM_PO } = VUEX_ACTIONS;
  const store = useStore();
  const expandedItem = ref();
  const expandedIndex = ref<number | null>(null);
  const expandedInvoiceDetails = ref(false);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const projectPurchaseOrders = computed(() => store.getters.getProjectPurchaseOrders);
  const applicationPurchaseOrders = computed(() => store.getters.getApplicationPurchaseOrders);
  const getIsApplicationPOListFetched = computed(() => store.getters.getIsApplicationPOListFetched);
  const getIsProjectPOListFetched = computed(() => store.getters.getIsProjectPOListFetched);
  const invoices = computed(() => store.getters.getInvoicesFromProjAppPO);
  const areInvoicesforPOFetched = computed(() => store.getters.getAreInvoicesForPOFetched);
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const selectedInvoice = ref();
  let listItemID;

  let listItemsfromPO;
  const sumLIfromInvoices = ref(0);

  const tableHeaders: Ref<string[]> = ref(["PO Date", "PO Name", "PO Owner", "Customer Contact", "Order Amount", "Current Year", "Details"]);

  const toggleDetails = async (item: IInvoice, index: number) => {
    if (expandedIndex.value === index) {
      expandedIndex.value = null;
      sumLIfromInvoices.value = 0;
    } else {
      expandedIndex.value = index;
      expandedItem.value = item;
      listItemsfromPO = expandedItem.value.listitems;

      store.commit("setAreInvoicesForPOFetched", false);
      await store.dispatch(FETCH_INVOICES_FROM_PO, item.id);

      listItemsfromPO.forEach((listItem: IListItem) => {
        listItemID = listItem.id;
        sumLIfromInvoices.value = sumListItemAmountsById(listItemID.toString());
      });
    }
  };

  function sumListItemAmountsById(listItemID: string) {
    let sum = 0;
    invoices.value.forEach((invoice: IInvoiceFromPO) => {
      invoice.listitems.forEach((listItem) => {
        if (listItem.po_listitem_id === String(listItemID)) {
          sum += parseFloat(listItem.amount);
        }
      });
    });
    return sum;
  }
  const users = computed(() => store.getters.getUsers);
  const selectedInvoiceName = ref("");

  function convertUsernameFromId(id: number): string {
    let ownerUsername = "";
    let filteredUsers = users.value;
    filteredUsers.filter((user: IUser) => {
      if (user.id === id) {
        ownerUsername = user.username;
      }
    });
    return ownerUsername;
  }

  function showInvoiceDetails(invoice: IInvoiceFromPO) {
    if (expandedInvoiceDetails.value && invoice.id === selectedInvoice.value?.id) {
      selectedInvoice.value = ref();
      expandedInvoiceDetails.value = !expandedInvoiceDetails.value;
    } else if (expandedInvoiceDetails.value && invoice.id !== selectedInvoice.value?.id) {
      selectedInvoice.value = invoice;
      selectedInvoiceName.value = "";
      selectedInvoiceName.value = invoice.name;
    } else {
      selectedInvoice.value = invoice;
      expandedInvoiceDetails.value = !expandedInvoiceDetails.value;
      selectedInvoiceName.value = "";
      selectedInvoiceName.value = invoice.name;
    }
  }

  const calculateTotalInvoiceAmount = computed(() => {
    let invoicesTotalAmount = 0;
    if (invoices.value) {
      invoices.value.forEach((item: IInvoiceFromPO) => {
        invoicesTotalAmount += item.total_amount;
      });
    }

    return invoicesTotalAmount;
  });

  const handleCloseProjectAppModal = () => {
    emit("isProjectAppModalVisible");
    store.dispatch(UPDATE_PO_LIST_FETCHED_STATE);
  };

return (_ctx: any,_cache: any) => {
  return (__props.isModalEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(__props.projappName) + " Details", 1),
            _createElementVNode("img", {
              src: _imports_0,
              height: "30",
              width: "30",
              alt: "Close modal icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (handleCloseProjectAppModal()))
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (getIsApplicationPOListFetched.value || getIsProjectPOListFetched.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (projectPurchaseOrders.value?.length > 0 || applicationPurchaseOrders.value?.length > 0)
                        ? (_openBlock(), _createElementBlock("table", _hoisted_9, [
                            _createElementVNode("thead", null, [
                              _createElementVNode("tr", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tableHeaders.value, (item, index) => {
                                  return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(item), 1))
                                }), 128))
                              ])
                            ]),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.type === 'project' ? projectPurchaseOrders.value : applicationPurchaseOrders.value, (item, index) => {
                              return (_openBlock(), _createElementBlock("tbody", {
                                key: item.id
                              }, [
                                _createElementVNode("tr", _hoisted_11, [
                                  _createElementVNode("td", null, _toDisplayString(_unref(formatDate)(item.podate)), 1),
                                  _createElementVNode("td", null, _toDisplayString(item.ordertext), 1),
                                  _createElementVNode("td", null, _toDisplayString(convertUsernameFromId(item.prowner)), 1),
                                  _createElementVNode("td", null, _toDisplayString(item.customercontact), 1),
                                  _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)((item.orderamount * currencyRate.value).toFixed(2))) + " EUR ", 1),
                                  _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear * currencyRate.value).toFixed(2))) + " EUR ", 1),
                                  _createElementVNode("td", null, [
                                    _createElementVNode("button", {
                                      title: "Show",
                                      class: "c-requestbtns c-requestbtns-secondary-show",
                                      "data-testid": "vshowinvoices-detailsbtn",
                                      onClick: ($event: any) => (toggleDetails(item, index))
                                    }, [
                                      _createElementVNode("p", null, _toDisplayString(expandedIndex.value === index ? "Hide" : "Show"), 1),
                                      _cache[1] || (_cache[1] = _createElementVNode("svg", {
                                        fill: "#000000",
                                        width: "15px",
                                        height: "15px",
                                        viewBox: "-1 0 19 19",
                                        class: "cf-icon-svg"
                                      }, [
                                        _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                      ], -1))
                                    ], 8, _hoisted_12)
                                  ])
                                ]),
                                (expandedIndex.value !== null && expandedIndex.value === index)
                                  ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                                      _createElementVNode("td", _hoisted_14, [
                                        (expandedItem.value.listitems && expandedItem.value.listitems.length > 0)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                              _createElementVNode("h4", _hoisted_16, [
                                                _cache[2] || (_cache[2] = _createElementVNode("strong", null, "List Items In: ", -1)),
                                                _createTextVNode(_toDisplayString(expandedItem.value.ordertext), 1)
                                              ]),
                                              _cache[3] || (_cache[3] = _createElementVNode("div", {
                                                class: "v-showinvoices__li-table-header",
                                                "data-testid": "vshowinvoices-listitems"
                                              }, [
                                                _createElementVNode("p", { class: "v-showinvoices__li-table-header--first" }, "No."),
                                                _createElementVNode("p", null, "Name"),
                                                _createElementVNode("p", null, "Cost Type"),
                                                _createElementVNode("p", null, "Resource"),
                                                _createElementVNode("p", null, "Person Days"),
                                                _createElementVNode("p", null, "Daily rate"),
                                                _createElementVNode("p", { class: "v-showinvoices__li-table-header--last" }, "Amount"),
                                                _createElementVNode("p", null, "Current Year"),
                                                _createElementVNode("p", null, "Remaining")
                                              ], -1)),
                                              _createElementVNode("div", _hoisted_17, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expandedItem.value?.listitems, (item, index) => {
                                                  return (_openBlock(), _createElementBlock("div", {
                                                    key: item.id
                                                  }, [
                                                    (item && typeof item === 'object')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                          _createElementVNode("p", _hoisted_19, _toDisplayString(index + 1), 1),
                                                          _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                                          _createElementVNode("p", null, _toDisplayString(item.costtype), 1),
                                                          _createElementVNode("p", null, _toDisplayString(item.resourcename != "" ? item.resourcename : "-"), 1),
                                                          _createElementVNode("p", null, _toDisplayString(item.quantity != 0 ? item.quantity : "-"), 1),
                                                          _createElementVNode("p", null, _toDisplayString(item.daily_rate), 1),
                                                          _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.amount * currencyRate.value).toFixed(2)) + " " + item.currency), 1),
                                                          _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear * currencyRate.value).toFixed(2)) + " " + item.currency), 1),
                                                          _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)(Number(item.li_remaining_amount * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                }), 128))
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (__props.isplatform == false)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                              _createElementVNode("h4", _hoisted_21, [
                                                _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Invoices Including:", -1)),
                                                _createTextVNode(" " + _toDisplayString(expandedItem.value.ordertext), 1)
                                              ]),
                                              (!areInvoicesforPOFetched.value)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_22))
                                                : (invoices.value.length > 0)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                      _cache[10] || (_cache[10] = _createElementVNode("div", {
                                                        class: "v-showinvoices__li-table-header",
                                                        "data-testid": "vshowinvoices-listitems"
                                                      }, [
                                                        _createElementVNode("p", null, "Name"),
                                                        _createElementVNode("p", null, "Total Amount"),
                                                        _createElementVNode("p", { class: "v-showinvoices__li-table-header--last" }, "Details")
                                                      ], -1)),
                                                      _createElementVNode("div", _hoisted_24, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invoices.value, (item) => {
                                                          return (_openBlock(), _createElementBlock("div", {
                                                            key: item.id
                                                          }, [
                                                            (item && typeof item === 'object')
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                                                  _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                                                  _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.total_amount * currencyRate.value).toFixed(2)) + " " + selectedCurrency.value), 1),
                                                                  _createElementVNode("div", null, [
                                                                    _createElementVNode("button", {
                                                                      class: "c-requestbtns c-requestbtns-secondary-show",
                                                                      onClick: ($event: any) => (showInvoiceDetails(item))
                                                                    }, [
                                                                      _createTextVNode(_toDisplayString(expandedInvoiceDetails.value && selectedInvoice.value.id === item.id ? "Hide" : "Show") + " ", 1),
                                                                      _cache[5] || (_cache[5] = _createElementVNode("svg", {
                                                                        width: "12px",
                                                                        height: "12px",
                                                                        fill: "#000000",
                                                                        viewBox: "-1 0 19 19",
                                                                        class: "cf-icon-svg"
                                                                      }, [
                                                                        _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                                                      ], -1))
                                                                    ], 8, _hoisted_26)
                                                                  ])
                                                                ]))
                                                              : _createCommentVNode("", true)
                                                          ]))
                                                        }), 128))
                                                      ]),
                                                      (expandedInvoiceDetails.value)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                                            _createElementVNode("h4", _hoisted_28, [
                                                              _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Invoice Details: ", -1)),
                                                              _createTextVNode(_toDisplayString(selectedInvoice.value.name), 1)
                                                            ]),
                                                            _cache[7] || (_cache[7] = _createElementVNode("div", {
                                                              class: "v-showinvoices__li-table-header",
                                                              "data-testid": "vshowinvoices-listitems"
                                                            }, [
                                                              _createElementVNode("p", { class: "v-showinvoices__li-table-header--first" }, "No."),
                                                              _createElementVNode("p", null, "Name"),
                                                              _createElementVNode("p", null, "Cost Type"),
                                                              _createElementVNode("p", null, "Resource"),
                                                              _createElementVNode("p", null, "Person Days"),
                                                              _createElementVNode("p", null, "Daily rate"),
                                                              _createElementVNode("p", null, "Amount")
                                                            ], -1)),
                                                            _createElementVNode("div", _hoisted_29, [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedInvoice.value.listitems, (item, index) => {
                                                                return (_openBlock(), _createElementBlock("div", {
                                                                  key: item.id
                                                                }, [
                                                                  (item && typeof item === 'object')
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                                                        _createElementVNode("p", _hoisted_31, _toDisplayString(index + 1), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(item.list_item_name), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(item.cost_type), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(item.resource_name ? item.resource_name : "-"), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(item.quantity && item.quantity > 0 ? item.quantity : "-"), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(item.daily_rate), 1),
                                                                        _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.amount * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                                                      ]))
                                                                    : _createCommentVNode("", true)
                                                                ]))
                                                              }), 128))
                                                            ])
                                                          ]))
                                                        : _createCommentVNode("", true),
                                                      _createElementVNode("div", _hoisted_32, [
                                                        _createElementVNode("div", null, [
                                                          _cache[8] || (_cache[8] = _createElementVNode("strong", null, "Total Invoice Amount:", -1)),
                                                          _createTextVNode(" " + _toDisplayString(_unref(formatNumber)((calculateTotalInvoiceAmount.value * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                                        ]),
                                                        _createElementVNode("div", null, [
                                                          _cache[9] || (_cache[9] = _createElementVNode("strong", null, "Remaining PO Amount:", -1)),
                                                          _createTextVNode(" " + _toDisplayString(_unref(formatNumber)(Number(item.po_remaining_amount * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                                        ])
                                                      ])
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("div", _hoisted_33, "No invoices found for this " + _toDisplayString(__props.type) + ".", 1))
                                            ]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_34, "No purchase orders found for this " + _toDisplayString(__props.type) + ".", 1))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_35))
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})