import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../public/remove-white.svg'


const _hoisted_1 = { class: "c-future-financials-modal--background" }
const _hoisted_2 = { class: "c-future-financials-modal" }
const _hoisted_3 = { class: "c-future-financials-modal__header" }
const _hoisted_4 = { class: "c-future-financials-modal__container" }
const _hoisted_5 = { class: "c-future-financials-modal__container__messages" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "c-future-financials-modal__container__labels--message" }
const _hoisted_8 = {
  key: 1,
  class: "cst-spinner"
}
const _hoisted_9 = { class: "c-future-financials-modal__footer" }
const _hoisted_10 = { class: "cst-input-field" }

import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import { formatDateWithTime } from "@/utils/helpers/formatDate";
  import { onBeforeUnmount, onMounted, ref, computed } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_FutureFinancialsModal',
  props: {
    entity: {},
    financialGoalLabel: {}
  },
  emits: ["close-details-modal"],
  setup(__props: any, { emit: __emit }) {

  const { CREATE_FINANCIAL_PLAN_COMMENT } = VUEX_ACTIONS;
  const props = __props;
  const emit = __emit;
  const store = useStore();
  const specificFutureFinancials = computed(() => store.getters.getSpecificFutureFinancials);
  const message = ref("");
  const users = computed(() => store.getters.getUsers);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const currentUserId = computed(() => {
    const userId = users.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });

  const closeModal = () => {
    store.commit("setSpecificFutureFinancials", null);
    emit("close-details-modal");
  };

  const convertUsernameFromId = (username: string) => {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  };
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const handleAddComment = () => {
    const payload = {
      sender_id: Number(currentUserId.value),
      future_budget_id: Number(props.entity.id),
      message: message.value,
    };
    store.dispatch(CREATE_FINANCIAL_PLAN_COMMENT, payload);
    message.value = "";
  };

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(props.entity?.entity_name ? props.entity.entity_name + ` Future ${_ctx.financialGoalLabel} ` : `Future ${_ctx.financialGoalLabel} `) + " Details ", 1),
        _createElementVNode("img", {
          src: _imports_0,
          alt: "close modal button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal())),
          "data-testid": "future-financials-closemodal-button"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "c-future-financials-modal__container__labels" }, [
          _createElementVNode("p", null, "Date"),
          _createElementVNode("p", null, "Sender"),
          _createElementVNode("p", { class: "c-future-financials-modal__container__labels--message" }, "Message")
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          (specificFutureFinancials.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(specificFutureFinancials.value.comments, (comment) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: comment.id,
                    class: "c-future-financials-modal__container__messages--message"
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, _toDisplayString(_unref(formatDateWithTime)(comment.time)), 1)
                    ]),
                    _createElementVNode("p", null, _toDisplayString(convertUsernameFromId(comment.sender_id)), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(comment.message), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "comment" }, "Comment:", -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "cst-input c-future-financials-modal__messagebox",
            type: "textarea",
            id: "comment",
            maxlength: "240",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((message).value = $event))
          }, null, 512), [
            [_vModelText, message.value]
          ]),
          _createElementVNode("span", {
            class: _normalizeClass(["cst-input-field__indicator", {
              'cst-input-field__indicator--max': message.value?.length == 240,
            }])
          }, _toDisplayString(message.value?.length ? `${message.value.length}` : `0`) + "/240", 3)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: handleAddComment
        }, "Add")
      ])
    ])
  ]))
}
}

})