import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cst-new-product-type--wrapper",
  "data-testid": "financialrequestreview-wrapper"
}
const _hoisted_2 = { class: "cst-inputbox--header" }
const _hoisted_3 = {
  class: "cst-inputbox--header__title",
  "data-testid": "financialrequestreview-inputbox-header"
}
const _hoisted_4 = {
  key: 0,
  class: "c-financialrequestreview",
  "data-testid": "financialrequestreview-no-such-entities"
}
const _hoisted_5 = {
  key: 1,
  class: "c-financialrequestreview--empty",
  "data-testid": "financialrequestreview-empty"
}
const _hoisted_6 = {
  key: 2,
  "data-testid": "financialrequestreview-spinner",
  class: "cst-spinner"
}

import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import C_FinancialsCard from "./cards/C_FinancialsCard.vue";
  import C_FutureFinancialsModal from "./modals/C_FutureFinancialsModal.vue";
  import C_FutureFinancialsDecisionModal from "./modals/C_FutureFinancialsDecisionModal.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import C_Search from "./C_Search.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_FinancialRequestReview',
  props: {
    isoverview: Boolean,
    financialGoalLabel: String,
  },
  setup(__props) {

  const { FETCH_FUTURE_FINANCIALS, FETCH_SPECIFIC_FUTURE_FINANCIALS, FETCH_FUTURE_PROPOSAL_FILTER } = VUEX_ACTIONS;
  const props = __props;
  const store = useStore();
  const futureFinancials = computed(() => {
    const financialGoal = store.getters.getFutureFinancials;
    if (props.isoverview) {
      return financialGoal.filter((item: IFinancialPlan) => item.status == "approved");
    } else {
      return financialGoal.filter((item: IFinancialPlan) => item.status !== "approved");
    }
  });
  const filteredFinancialPlan = ref(futureFinancials.value);
  const areFutureFinancialsFetched = computed(() => store.getters.getFutureFinancialsFetched);
  const selectedEntity = ref<IFinancialPlan>({});
  const showDetailsModal = ref(false);
  const showDecisionModal = ref(false);
  const isApproval = ref(false);
  store.dispatch(FETCH_FUTURE_FINANCIALS);

  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);

  const handleFutureFinancialsDetails = (entity: IFinancialPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_FINANCIALS, selectedEntity.value.id);
  };

  const handleFutureFinancialsApproval = (entity: IFinancialPlan) => {
    isApproval.value = true;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const handleFutureFinancialsDecline = (entity: IFinancialPlan) => {
    isApproval.value = false;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const filterFutureFinancialPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredFinancialPlan.value = futureFinancials.value.filter((financial_goal: IFinancialPlan) => {
      if (financial_goal?.entity_name) return financial_goal?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  watch([futureFinancials], () => {
    filteredFinancialPlan.value = futureFinancials.value;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (futureFinancials.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["cst-inputbox", { 'c-financialrequestreview--overview': props.isoverview }]),
            "data-testid": "financialrequestreview-inputbox"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(props.isoverview ? `Approved Future ${__props.financialGoalLabel} Entities` : "Requests In Review"), 1)
            ]),
            _createVNode(C_Search, {
              onInput: _cache[0] || (_cache[0] = ($event: any) => (filterFutureFinancialPlans($event))),
              placeholder: `Search future ${__props.financialGoalLabel}...`
            }, null, 8, ["placeholder"]),
            _createElementVNode("div", {
              class: _normalizeClass(["c-financialrequestreview--wrapper", {
          'c-financialrequestreview--wrapper--overview': props.isoverview,
        }]),
              "data-testid": "financialrequestreview-card-wrapper"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFinancialPlan.value, (entity) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "c-financialrequestreview",
                  key: entity.id
                }, [
                  _createVNode(C_FinancialsCard, {
                    onFutureFinancialsDetails: handleFutureFinancialsDetails,
                    onFutureFinancialsApprove: handleFutureFinancialsApproval,
                    onFutureFinancialsDecline: handleFutureFinancialsDecline,
                    entity: entity,
                    isoverview: props.isoverview,
                    financialGoalLabel: __props.financialGoalLabel
                  }, null, 8, ["entity", "isoverview", "financialGoalLabel"])
                ]))
              }), 128)),
              (filteredFinancialPlan.value.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "There are no such entities."))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ]))
      : (__props.isoverview && areFutureFinancialsFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "No approved future financial goal requests available."))
        : (!areFutureFinancialsFetched.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true),
    (showDetailsModal.value)
      ? (_openBlock(), _createBlock(C_FutureFinancialsModal, {
          key: 3,
          onCloseDetailsModal: _cache[1] || (_cache[1] = ($event: any) => (showDetailsModal.value = !showDetailsModal.value)),
          entity: selectedEntity.value,
          financialGoalLabel: __props.financialGoalLabel
        }, null, 8, ["entity", "financialGoalLabel"]))
      : _createCommentVNode("", true),
    (showDecisionModal.value)
      ? (_openBlock(), _createBlock(C_FutureFinancialsDecisionModal, {
          key: 4,
          entity: selectedEntity.value,
          onCloseDecisionModal: _cache[2] || (_cache[2] = ($event: any) => (showDecisionModal.value = !showDecisionModal.value)),
          "is-approval": isApproval.value,
          financialGoalLabel: __props.financialGoalLabel
        }, null, 8, ["entity", "is-approval", "financialGoalLabel"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})