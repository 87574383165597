<template>
  <div class="c-financialscard__wrapper" data-testid="financialscard-wrapper">
    <p class="c-financialscard__product-name" data-testid="financialscard-name">
      {{ entity?.entity_name ? entity.entity_name : `Future ${financialGoalLabel}` }}
    </p>
    <p
      class="c-financialscard__type"
      :class="{
        'c-financialscard__type--review': entity?.status === 'proposed',
        'c-financialscard__type--declined': entity?.status === 'declined',
        'c-financialscard__type--approved': entity?.status === 'approved',
      }"
      data-testid="financialscard-status"
    >
      {{ entity?.status ? entity.status : "Proposed" }}
    </p>

    <div class="c-financialscard__owner-wrapper">
      <p class="c-financialscard__owner-label">Owner:</p>
      <p data-testid="financialscard-owner">
        {{ entity?.owner ? entity.owner : "-" }}
      </p>
    </div>
    <div class="c-financialscard__summary">
      <div class="c-financialscard__row">
        <div class="section__line-with-text">
          <p class="section__title">Demanded</p>
        </div>
      </div>
      <div class="c-financialscard__row">
        <p class="c-financialscard__row--placeholder">Demand ID</p>
        <p class="c-financialscard__row--value" data-testid="financialscard-demand-id">
          {{ entity?.demand_id ? entity.demand_id : "-" }}
        </p>
      </div>
      <div class="c-financialscard__row">
        <p class="c-financialscard__row--placeholder">Demand Title</p>
        <p class="c-financialscard__row--value" data-testid="financialscard-demand-title">
          {{ entity?.demand_title ? entity.demand_title : "-" }}
        </p>
      </div>
    </div>
    <div class="c-financialscard__summary">
      <div class="c-financialscard__row">
        <p class="c-financialscard__row--placeholder">
          Demanded <span>{{ financialGoalLabel }}</span>
        </p>
        <p class="c-financialscard__row--value" data-testid="financialscard-demand-value">
          {{ entity?.demanded_budget ? formatNumber((Number(entity.demanded_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>
      <div class="c-financialscard__row">
        <div class="section__line-with-text">
          <p class="section__title">Proposed</p>
        </div>
      </div>
      <div class="c-financialscard__row">
        <p class="c-financialscard__row--placeholder">
          Proposed <span>{{ financialGoalLabel }}</span>
        </p>
        <p class="c-financialscard__row--value" data-testid="financialscard-proposed-value">
          {{ entity?.proposed_budget ? formatNumber((Number(entity.proposed_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>
      <div class="c-financialscard__row">
        <p class="c-financialscard__row--placeholder">
          Approved <span>{{ financialGoalLabel }}</span>
        </p>
        <p class="c-financialscard__row--value" data-testid="financialscard-approved-value">
          {{ entity?.approved_budget ? formatNumber((Number(entity.approved_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>

      <div class="c-financialscard__button--wrapper">
        <button
          type="button"
          class="cst-button-primary c-productcard__button"
          @click="$emit('future-financials-approve', entity)"
          v-if="!props.isoverview && router.currentRoute.value.path.toLowerCase() == '/proposal' && entity?.proposed_by == currentUser"
          data-testid="financialscard-btn-approve"
        >
          Approve
        </button>
        <button
          type="button"
          class="cst-button-primary c-productcard__button"
          @click="$emit('future-financials-decline', entity)"
          v-if="!props.isoverview && router.currentRoute.value.path.toLowerCase() == '/proposal' && entity?.proposed_by == currentUser"
          data-testid="financialscard-btn-decline"
        >
          Decline
        </button>
        <button type="button" class="cst-button-primary c-productcard__button c-financialscard__button--details" @click="$emit('future-financials-details', entity)" data-testid="financialscard-btn-details">Details</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { IFinancialPlan } from "@/utils/interfaces/IFinancialPlan";
  import { computed, defineProps } from "vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";

  const store = useStore();
  const router = useRouter();
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  const props = defineProps<{
    entity: IFinancialPlan;
    isoverview: boolean;
  }>();
</script>
